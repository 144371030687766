@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-test-result-status-progress {
  .box-progress {
    background: #f1f1f1;
    border-radius: 8px;
    padding: 18px 30px;
  }
  .txt-number {
    font-weight: 500;
    font-size: 26px;
    line-height: 1.2;
  }
  .txt-label {
    font-size: 16px;
  }
  .c-basic-progress-bar-wrapper {
    height: 20px;
    border-radius: 10px;
  }
  .box-status-dot {
    span {
      display: inline-block;
      vertical-align: -0.2em;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #b9b9b9;
    }
  }
}
