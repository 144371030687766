@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-left-sidebar {
  .ant-spin {
    position: absolute;
    left: 50%;
    top: 43vh;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  .ant-menu {
    height: 100%;
    background: none;
    border-right: 0;
    .ant-menu-item.inactive {
      padding-right: 35px;
      .ic-warning {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 16px;
        margin: 0;
      }
      .anticon {
        color: #d0d0d0 !important;
      }
      .title {
        color: #d0d0d0 !important;
        span {
          color: #fe3d3d !important;
        }
      }
    }
    .ant-menu-item .anticon.ant-menu-item-icon,
    .ant-menu-submenu .anticon.ant-menu-item-icon {
      min-width: 20px !important;
      font-size: 20px !important;
      line-height: 0 !important;
    }
  }
  .ant-layout-sider-trigger {
    display: none;
  }
  .box-header-sidebar {
    padding: 20px 24px;
    .txt-header-sidebar {
      font-weight: 500;
      font-size: 12px;
      color: #667085;
      text-transform: uppercase;
    }
    .btn-toggle-collapse {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      color: #000;
      background: #fff;
      border: 0;
      border-radius: 5px;
      outline: 0;
      box-shadow: 0px 0px 13.4px 0px #aeaeae40;
    }
  }
  .box-toggle-collapse {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 220px;
    min-height: 43px;
    background: #fff;
    border-top: 1px solid #eaecf0;
    transition: all 0.2s;
    z-index: 1;
    > * {
      height: 42px;
    }
    .btn-link,
    .btn-toggle-collapse {
      min-width: 42px;
    }
    .btn-link {
      flex: 1 1 calc(100% - 42px);
      display: flex;
      align-items: center;
      color: $primary;
      border: 0;
      padding-left: 24px;
      .anticon {
        font-size: 20px;
        margin-right: 10px;
      }
    }
    .btn-toggle-collapse {
      color: $body-color;
      border: 0;
    }
  }
  .box-toggle-collapse-2 {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 220px;
    height: 43px;
    background: #fff;
    border-top: 1px solid #eaecf0;
  }
}
.c-left-sidebar {
  & + * {
    flex: 0 0 calc(100% - 220px);
    max-width: calc(100% - 220px);
    min-width: calc(100% - 220px);
    width: calc(100% - 220px);
  }
  &.for-project-setting-sidebar {
    & + * {
      flex: 0 0 calc(100% - 250px);
      max-width: calc(100% - 250px);
      min-width: calc(100% - 250px);
      width: calc(100% - 250px);
    }
  }
  &.ant-layout-sider-collapsed {
    & + * {
      flex: 0 0 calc(100% - 80px);
      max-width: calc(100% - 80px);
      min-width: calc(100% - 80px);
      width: calc(100% - 80px);
    }
    .box-header-sidebar {
      padding: 20px 15px;
    }
    .box-toggle-collapse {
      .btn-link,
      .btn-toggle-collapse {
        width: 100%;
        flex: 0 0 100%;
      }
      .btn-link {
        padding: 0 32px;
        .anticon {
          margin: 0;
        }
      }
    }
    .ant-menu-item-group {
      .ant-menu-item-group-title {
        display: none;
      }
    }
  }
  &.ant-layout-sider-has-trigger {
    padding-bottom: 90px;
  }
}
.c-left-sidebar-sub-menu-popup {
  .ant-menu-item .anticon.ant-menu-item-icon,
  .ant-menu-submenu .anticon.ant-menu-item-icon {
    min-width: 20px !important;
    font-size: 20px !important;
  }
}
