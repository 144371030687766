@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-prepare-attach-screenshot-modal {
  .c-basic-preview-file {
    .box-preview-file {
      width: auto;
      height: auto;
      background: none;
      border: 1px dashed #d2cdcd;
      border-radius: 5px;
      .c-other-file .box-other-file {
        background: none;
        box-shadow: none;
        .ic-file {
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
    .c-view-file,
    .c-react-pan-zoom-image,
    .pan-container {
      height: calc(100vh - 263px);
    }
    .pan-container img {
      max-height: calc(100vh - 263px);
    }
    .c-react-pan-zoom-image .box-action {
      bottom: 5px;
      background: #fff;
      box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.10);
      .ant-btn,
      .txt-zoom-percent  {
        color: $body-color;
      }
      .ant-btn {
        &:hover {
          background: darken(#fff, 10%);
        }
      }
    }
  }
}
