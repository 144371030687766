// ---------- ant-form-item
.ant-form-item-label {
  white-space: normal;
  line-height: 1.3;
  > label {
    font-weight: 500;
  }
  > label > .anticon.ant-form-item-tooltip {
    vertical-align: middle;
  }
}
// ---------- ant-form
.detail-form {
  .ant-form-item-label > label {
    color: #6e6e6e;
  }
  .ant-form-item-control {
    color: rgba(0, 0, 0, 1);
  }
}
.is-hide-control-input {
  margin-bottom: 0;
  .ant-form-item-control-input {
    height: 0;
    min-height: 0;
    overflow: hidden;
  }
}
.label-truncate .ant-form-item-label > label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 0.375rem;
}
.ant-form-item.has-icon-required > .ant-form-item-label > label:before {
  display: inline-block;
  margin-right: 4px;
  color: $danger;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.has-required-prefix-icon:before {
  display: inline-block;
  color: $danger;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
table .has-required-prefix-icon:before {
  display: none;
}
.show-required-icon-on-title-column table .has-required-prefix-icon:before {
  display: inline;
}
.has-enter-and-clear-button .ant-input-search-button {
  width: 32px;
  padding: 0;
}
.bg-input-white .ant-input {
  background: #fff !important;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: $focus-input;
}
// ---------- ant-input
.ant-input-affix-wrapper,
.ant-input,
.ant-input-password {
  border-radius: 5px !important;
}
.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
  border-color: $focus-input;
}
.ant-input:focus,
.ant-input-focused {
  box-shadow: 0 0 0 2px rgba($focus-input, 0.2);
}
.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  border-color: $danger;
}
.input-gray {
  border-color: #f0f0f0;
  .ant-input {
    background: #f0f0f0 !important;
  }
  .ant-input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px #f0f0f0 inset !important;
    }
  }
}
.ant-input-affix-wrapper.text-center .ant-input {
  text-align: center;
}
.ant-input.only-bd-bottom,
.ant-input-affix-wrapper.only-bd-bottom {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  box-shadow: none;
  &:focus {
    box-shadow: none;
  }
}
.ant-input-clear-icon {
  line-height: 1 !important;
}
.ant-input-xl {
  padding: 9.9px 11px;
  font-size: 18px;
  @include mq(max_lg) {
    padding: 5.9px 11px;
    font-size: 16px;
  }
}
.input-gray {
  &:hover,
  &:focus {
    border-color: $focus-input;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #f0f0f0 inset !important;
  }
}
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $focus-input;
  box-shadow: 0 0 0 2px rgba($focus-input, 0.1);
}
.ant-input-search-with-button {
  .ant-input-affix-wrapper {
    border-top-left-radius: 5px !important;
    border-end-start-radius: 5px !important;
  }
  .ant-input-search-button {
    border-top-left-radius: 0 !important;
    border-end-start-radius: 0 !important;
  }
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $focus-input;
}
.ant-input[readonly],
.ant-input.readonly,
.ant-input-affix-wrapper-readonly {
  background: #f5f5f5;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-top-left-radius: 0 !important;
  border-end-start-radius: 0 !important;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: $focus-input;
}
.ant-input.only-show-text {
  border: 0;
  box-shadow: none;
  background: #fff;
  padding: 0;
}
.ant-input.ant-input-borderless {
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}
textarea.ant-input.ant-input-borderless {
  resize: none;
}
textarea.ant-input.ant-input-borderless {
  &:focus {
    resize: both;
  }
}
.has-copy-icon .ant-typography {
  margin-bottom: 0;
  .ant-typography-copy {
    margin-left: 0;
  }
}

/* Change the white to any color autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
.visual-field {
  pointer-events: none;
}
.visual-field {
  & + * {
    opacity: 0;
  }
}

// ---------- ant-input-number-input
.ant-input-number:hover {
  border-color: $focus-input;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: $focus-input;
  box-shadow: 0 0 0 2px rgba($focus-input, 0.2);
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: $primary;
}
.ant-input-number.no-border {
  border: 0;
  background: none;
  .ant-input-number-input {
    padding: 0;
  }
  .ant-input-number-handler-wrap {
    opacity: 1;
    background: none;
    border: 0;
  }
  .ant-input-number-handler {
    background: none;
  }
  .ant-input-number-handler-down {
    border: 0;
  }
}
.ant-input-number.no-border {
  &.ant-input-number-focused {
    box-shadow: none;
  }
}

// ---------- ant-checkbox
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary;
  border-color: $primary;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary;
}
.ant-tree-checkbox-inner::after {
  left: 21%;
}
.ant-checkbox-checked::after {
  border: 0px solid $primary;
}
.ant-checkbox-inner::after {
  left: 21%;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $primary;
}
.ant-checkbox-group.direction-vetical-group {
  .ant-checkbox-group-item {
    flex: 1 1 auto;
    display: flex;
    width: 100%;
    margin: 0 0 8px 0;
  }
}
.ant-checkbox-top-0 .ant-checkbox {
  top: 0 !important;
}

// ---------- ant-tree-checkbox
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: $primary;
  border-color: $primary;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: $primary;
}
.ant-tree-checkbox-checked::after {
  border: 1px solid $primary;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: $primary;
}
.ant-tree-checkbox-group.d-block-tree-checkbox-item {
  display: block;
  .ant-tree-checkbox-group-item {
    display: block;
  }
}
// ---------- ant-radio
.ant-radio-checked .ant-radio-inner {
  border-color: $primary;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $primary;
}
.ant-radio-checked::after {
  border: 1px solid $primary;
}
.ant-radio-inner::after {
  background-color: $primary;
}
.ant-radio-button-wrapper {
  color: #686666;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
}
.ant-radio-button-wrapper:hover {
  color: $primary;
}
.ant-radio-button-wrapper:first-child {
  border-right: 0 !important;
  border-left: 1px solid #f1f1f1;
}
.ant-radio-button-wrapper:not(:first-child) {
  border-left: 0 !important;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: none;
}
.ant-radio-button-wrapper {
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.ant-radio-group.rounded-full .ant-radio-button-wrapper {
  &:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  &:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
.ant-radio-button-wrapper a {
  color: #686666;
}
.ant-radio-button-wrapper:hover {
  color: $primary;
}
.ant-radio-button-wrapper::before {
  display: none !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: lighten($primary, 52%);
  border-color: lighten($primary, 52%);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: lighten($primary, 52%);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: lighten($primary, 46%);
  border-color: lighten($primary, 46%);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: lighten($primary, 43%);
  border-color: lighten($primary, 43%);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $primary;
  background: lighten($primary, 52%);
  border-color: lighten($primary, 52%);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: $primary;
  background: lighten($primary, 46%);
  border-color: lighten($primary, 46%);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: $primary;
  background: lighten($primary, 43%);
  border-color: lighten($primary, 43%);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}
.ant-radio-button-wrapper-checked:not(
    [class*=' ant-radio-button-wrapper-disabled']
  ).ant-radio-button-wrapper:first-child {
  border-right-color: lighten($primary, 46%);
}

// ---------- ant-select
.ant-select-selector {
  border-radius: 5px !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $focus-input;
  box-shadow: 0 0 0 2px rgba($focus-input, 0.2);
}
.ant-select.is-read-only {
  .ant-select-selector {
    cursor: default !important;
  }
  .ant-select-selection-item {
    color: $body-color;
  }
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: $focus-input;
}
.ant-select-single.ant-select-xl {
  font-size: 18px;
  @include mq(max_lg) {
    font-size: 16px;
  }
}
.ant-select-single.ant-select-xl:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
  @include mq(max_lg) {
    height: 42px;
  }
}
.ant-select-single.ant-select-xl:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-xl:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-xl:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 48px;
  @include mq(max_lg) {
    line-height: 40px;
  }
}
.ant-select-single.ant-select-xl:not(.ant-select-customize-input):not(.ant-select-customize-input)
  .ant-select-selection-search-input {
  height: 48px;
  @include mq(max_lg) {
    height: 40px;
  }
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 2px rgba($focus-input, 0.2);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba($primary, 0.2);
}
.ant-select-item-option-selected.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba($primary, 0.3);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: $primary;
}
.ant-select.ant-select-borderless {
  .ant-select-selector {
    padding: 0;
  }
  .ant-select-selector .ant-select-selection-search {
    left: 0;
  }
  .ant-select-arrow {
    right: 0;
  }
  .ant-select-clear {
    right: 0;
  }
}
.ant-select .ant-select-clear {
  border-radius: 50%;
}
.prefix-icon-for-selection {
  position: relative;
  .prefix-icon {
    position: absolute;
    left: 9px;
    top: 8.5px;
    z-index: 1;
  }
  .ant-select {
    .ant-select-selection-placeholder {
      left: 28px;
    }
  }
  .ant-select {
    &.ant-select-multiple {
      .ant-select-selector {
        padding-left: 28px;
      }
      .ant-select-selection-search:first-child .ant-select-selection-search-input {
        margin-left: 0;
      }
    }
  }
}
.ant-select-item-option-content {
  display: flex;
  align-items: center;
}
.ant-select-item-option-content .c-user-avatar {
  max-width: 100%;
}
.item-status.ant-select-item-option-disabled {
  opacity: 0.5;
}
.ant-select-arrow.ant-select-arrow-loading {
  top: 46%;
  right: 9px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: $primary;
}

.option-select-filed-status-jira {
  .ant-select-item-option-content {
    display: block !important;
    padding: 0 30px 0 0;
  }
}
// ---------- ant-select-tree
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: rgba($primary, 0.2);
}

// ---------- ant-upload
.ant-upload {
  &.ant-upload-disabled {
    cursor: default;
  }
  &.ant-upload-disabled {
    .ant-upload-drag {
      border-color: $primary !important;
    }
    .ant-upload-drag-icon {
      cursor: not-allowed;
    }
  }
  &.ant-upload-select-picture-card:hover,
  &.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled),
  &.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: $primary;
  }
}
.ant-upload.ant-upload-btn {
  border-radius: 5px;
}
.ant-upload.ant-upload-btn {
  &:focus {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }
}

// ---------- ant-switch
.ant-switch-checked {
  background: $primary;
}
.ant-switch-checked {
  &:focus {
    box-shadow: 0 0 0 2px rgba($primary, 0.2);
  }
}

// ---------- ant-picker
.ant-picker {
  border-radius: 5px !important;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: $focus-input;
}
.ant-picker-focused {
  box-shadow: 0 0 0 2px rgba($focus-input, 0.2);
}
.ant-picker-range .ant-picker-active-bar {
  background: $primary;
}
.ant-picker-range.w-100 {
  .ant-picker-input {
    input {
      text-align: center;
    }
  }
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $primary;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $primary;
}
.ant-picker-header-view button:hover,
.ant-picker-today-btn,
.ant-picker-today-btn:hover {
  color: $primary;
}
.ant-picker-input > input[disabled] {
  color: $body-color;
}
.hide-ok-button .ant-picker-ok {
  display: none;
}
.ant-picker.ant-picker-borderless {
  padding: 0;
}
.ant-form-item-has-error .ant-picker.ant-picker-disabled {
  background: #f5f5f5 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top: 1px dashed $primary;
  border-bottom: 1px dashed $primary;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(
    .ant-picker-cell-range-hover-edge-start-near-range
  )::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed $primary;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(
    .ant-picker-cell-range-hover-edge-end-near-range
  )::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed $primary;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: lighten($primary, 58%);
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: lighten($primary, 58%);
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: lighten($primary, 50%);
}
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: lighten($primary, 50%);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: lighten($primary, 50%);
}
.ant-picker-time-panel-column-active {
  background: lighten($primary, 60%);
}

// ---------- For border-radius
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0 !important;
}
.ant-input-group-addon {
  border-radius: 5px !important;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group > .ant-picker:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.ant-input-group.ant-input-group-compact > * {
  border-radius: 0 !important;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-radius: 0 !important;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0 !important;
}
.ant-input-group.ant-input-group-compact
  .ant-input-group-wrapper:not(:last-child).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon
  > .ant-input-search-button {
  border-radius: 0 !important;
}
.ant-input-group.ant-input-group-compact
  .ant-input-group-wrapper:not(:last-child).ant-input-search
  > .ant-input-group
  > .ant-input {
  border-radius: 5px 0 0 5px !important;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 5px 5px 0 !important;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 5px 0 0 5px !important;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 5px 0 0 5px !important;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 5px 5px 0 !important;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 5px 5px 0 !important;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-radius: 5px 0 0 5px !important;
}
.ant-input-group.ant-input-group-compact
  .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search
  > .ant-input-group
  > .ant-input {
  border-radius: 0 5px 5px 0 !important;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0 !important;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 0 5px 5px 0 !important;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 5px 0 0 5px !important;
}
