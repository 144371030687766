.ant-dropdown {
  .ant-dropdown-menu {
    border-radius: 5px;
  }
  .ant-dropdown-menu-item {
    .ant-btn {
      width: 100%;
      border: 0;
    }
    .ant-tooltip-disabled-compatible-wrapper {
      .ant-btn {
        text-align: left;
      }
    }
  }
  .ant-dropdown-menu-item.text-truncate .ant-dropdown-menu-title-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.ant-dropdown {
  &.ant-dropdown-menu-p-0 .ant-dropdown-menu {
    padding: 0;
  }
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: $primary;
  background: rgba($primary, 0.05);
}
.dropdown-with-checkbox-list-style {
  .checkbox-dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ant-dropdown-menu-item {
    cursor: default;
    .ant-checkbox-wrapper {
      span {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .text-unassigned {
        vertical-align: 0.3em;
      }
    }
    .ant-checkbox {
      & + span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .ant-dropdown-menu-title-content {
    width: 100%;
  }
}
.ant-dropdown.menu-item-padding-0 {
  .ant-dropdown-menu-item {
    padding: 0;
  }
}

.test-case-selection-dropdown-on-defect {
  .ant-select-item-option .txt-alt {
    font-weight: 500;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: normal;
  }
}
.test-case-selection-on-defect .txt-name {
  display: none;
}
.btn-toggle-dropdown-with-checkbox-list {
  display: flex;
  align-items: center;
  .anticon {
    font-size: 0.8em;
  }
}
.ant-select-dropdown.disable-select-deselect-for-tags {
  .ant-select-item {
    pointer-events: none;
    background: none;
  }
  .ant-select-item-option-state {
    display: none;
  }
}
