@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-progress-bar-v2 {
  position: relative;
  .progress-bar-tooltip-v2 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
.c-basic-progress-bar-wrapper-v2 {
  overflow: hidden;
  height: 5px;
  width: 100%;
  border-radius: 3px;
  background: #ddd;
}
.c-basic-progress-bar-summary-tooltip-v2 {
  .c-basic-progress-bar-wrapper-v2 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10px;
    border-radius: 0 0 6px 6px;
  }
}
