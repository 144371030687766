@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-advanced-aql-search-v2 {
  min-height: 46px;
  background: #f1f1f1;
  border-radius: 8px;
  padding: 3px 10px;
  .ant-input-borderless {
    background: none !important;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: none !important;
    }
  }
}
