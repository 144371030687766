@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-relation-item {
  .ant-collapse-content-box {
    width: 100%;
  }
  .relation-item {
    position: relative;
  }
  .panel-content-item {
    display: flex;
    align-items: center;
    width: 100%;
    background: #f5f5f5 !important;
    border-radius: 5px !important;
    padding: 3px 0 3px 0 !important;
    .wrapper-content {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-left: 10px;
    }
    .btn-delete {
      button {
        display: none;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 2;
      }
    }
  }
  .panel-content-item {
    &:hover {
      .btn-delete {
        button {
          display: block;
        }
      }
    }
  }
  .comment-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .ant-collapse {
    background: none;
  }
  .ant-collapse-item {
    padding: 0 10px 5px 10px;
    .ant-collapse-header {
      display: flex;
      padding: 0;
      .ant-collapse-arrow {
        top: 7px;
        left: 0;
        vertical-align: 0;
        font-size: 0.7em;
        margin-right: 6px;
      }
    }
    .ant-collapse-extra {
      opacity: 0;
      visibility: hidden;
      padding: 0;
      .ant-btn {
        width: auto;
        height: auto;
        line-height: 1;
        color: #6a6a6a;
        font-size: 0.8em;
        &:hover {
          color: $primary;
        }
      }
      .btn-delete {
        &:hover {
          color: $danger;
        }
      }
    }
    .ant-collapse-content {
      padding: 0;
    }
    .ant-collapse-content-box {
      padding-top: 6px;
      padding-bottom: 10px;
    }
  }
  .ant-collapse .ant-collapse-item {
    border-bottom: none !important;
  }
  .panel-content {
    display: flex;
    align-items: center;
  }
  .status-style {
    display: flex;
    justify-content: center;
  }
}
