.c-custom-field {
  &.type-relation {
    .ant-form-item-extra {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
    .txt-label > .component-type-picklist,
    .txt-label > .component-type-picklist,
    .txt-label > .component-type-picklist,
    .txt-label > .component-type-picklist,
    .txt-label > .ref-name-tag {
      // overflow: hidden;
      // text-overflow: ellipsis;
      white-space: initial;
    }
    .txt-label > .component-type-user,
    .txt-label > .component-type-priority {
      display: flex;
    }
    .txt-label > .component-type-user .c-user-avatar,
    .txt-label > .component-type-priority .ant-row {
      margin-left: 5px;
    }
    .txt-label > .component-type-status .c-status-label {
      position: relative;
      top: -1px;
    }
  }
}
.hidden-colons-title {
  &.ant-form-item-label {
    & > label::after {
      content: none;
    }
  }
}
