@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-type-to-confirm-modal {
  .ant-modal-close {
    display: none;
  }
  .ant-typography pre {
    word-break: break-all;
  }
}
