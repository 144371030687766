@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-main-footer {
  background: #fff;
  border-top: 1px solid #eaecf0;
  padding: 10px 15px;
  .txt-copyright {
  }
  .txt-powered {
    color: #667085;
    .powered {
      font-weight: 500;
      color: #344054;
    }
  }
}
