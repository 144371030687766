@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-editable-select-multi-user-option {
  display: flex;
  position: relative;
  width: auto;
  .ant-form-item {
    width: 100%;
    margin-bottom: 0;
  }
  .ant-form-item-control-input {
    min-height: 0;
  }
  .ant-input {
    width: 100%;
  }
  .box-txt-label {
    position: relative;
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 2px;
    padding-right: 29px;
    .txt-label {
      display: flex;
      align-items: center;
    }
    .btn-open-editable {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      opacity: 0;
      border: 0;
      border-left: 1px solid $border-color;
      border-radius: 0 !important;
      background: #f0f0f0;
      transition: background 0.4s ease;
      .anticon {
        font-size: 0.9em;
      }
      &:hover {
        background: darken(#f0f0f0, 8%);
      }
    }
  }
  .box-txt-label {
    padding-right: 29px;
    &:hover {
      border-color: $border-color;
      .btn-open-editable {
        opacity: 1;
      }
      .txt-label {
        background: #fff;
      }
    }
  }
  .ic-loading {
    position: absolute;
    right: 0;
    top: 2px;
    border: 0;
    background: none;
    outline: 0;
    box-shadow: none;
  }
  .btn-close-editable {
    display: flex;
    justify-content: center;
    align-items: center;
    .anticon {
      font-size: 0.7em;
    }
  }
}

.c-editable-select-multi-user-option {
  &.is-editing {
    display: block;
    width: 100%;
  }
  &.is-read-only .box-txt-label:hover {
    border-color: transparent;
  }
  &.is-show-editing-icon {
    .box-txt-label {
      .btn-open-editable {
        opacity: 1;
        border-left-color: transparent;
        background: none;
      }
      &:hover .btn-open-editable {
        border-left-color: $border-color;
        background: #f0f0f0;
      }
    }
  }
}
