@import '../../../assets/scss/_variables.scss';
@import '../../../assets/scss/_mixins.scss';

.c-config-grid-view-contents-field-list .field-btn,
.field-btn.row-dragging-field {
  position: relative;
  overflow: visible;
  border-radius: 5px;
  .btn-drag {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    overflow: visible;
    z-index: 2;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
  }
  .h3-field-name {
    width: 100%;
    height: 100%;
    padding-left: 50px;
    display: flex;
    align-items: center;
    color: #6c6b6b;
  }
}
.c-config-grid-view-contents-field-list .field-btn {
  width: 100%;
  height: 60px;
  background: #f8f8f8;
  border: 1px solid transparent;
  margin-bottom: 10px;
  &:hover {
    background: #fdf4f3;
    border-radius: 10px;
    color: $primary;
  }
}
.field-btn.row-dragging-field {
  background: #fdf4f3;
  border: 1px solid darken(#fdf4f3, 5%);
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.10);
  z-index: 9999;
}
