.c-directory-tree {
  .ant-tree {
    color: rgba(0, 0, 0, 0.85);
    .ant-tree-treenode-draggable .ant-tree-draggable-icon {
      width: 24px;
      flex: 0 0 24px;
      line-height: 30px;
    }
    .ant-tree-switcher {
      color: $body-color;
      line-height: 30px;
      .ant-tree-switcher-icon,
      .ant-select-tree-switcher-icon {
        font-size: 12px;
        vertical-align: middle;
      }
    }
    .ant-tree-treenode-selected {
      background: rgba($primary, 0.05);
      .ant-tree-switcher {
        color: $body-color;
      }
    }
    .ant-tree-node-content-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      overflow: hidden;
      line-height: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 0;
      padding-left: 0;
      .ant-tree-drop-indicator {
        bottom: 3px !important;
        background-color: $primary;
        &:after {
          position: absolute;
          top: -3px;
          width: 8px;
          height: 8px;
          border-color: $primary;
        }
      }
      .anticon {
        color: $primary;
        transition: none;
      }
      &:hover {
        background: none;
      }
      &.ant-tree-node-selected {
        background: none;
        .anticon {
          color: $primary;
        }
      }
    }
    .ant-tree-treenode {
      align-items: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-bottom: 0;
      margin-bottom: 4px;
      .ant-tree-iconEle {
        flex: 0 0 30px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        .anticon {
          font-size: 20px;
          padding-top: 5px;
        }
      }
      .btn-dots-option {
        opacity: 0;
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 24px;
        height: 30px;
        color: $body-color;
        background: none;
        border: 0;
        outline: 0;
        box-shadow: none;
        text-align: center;
        transition: color 0.4s ease;
        z-index: 2;
        .anticon {
          color: inherit !important;
        }
      }
      .btn-dots-option {
        &:hover {
          color: $primary;
        }
        &:after {
          display: none;
        }
        &.ant-dropdown-open {
          opacity: 1;
        }
      }
      .ant-tree-title {
        flex: 1 1 calc(100% - 30px);
        width: calc(100% - 30px);
        line-height: 1.5;
        padding-left: 3px;
        padding-right: 24px;
      }
      .box-title-item {
        position: relative;
        display: inline-flex;
        align-items: center;
        max-width: 100%;
        .btn-sync {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          width: 18px;
          height: 100%;
          line-height: 1;
          color: $orange;
          border: 0;
          outline: 0;
          background: none;
          box-shadow: none;
          transition: none;
          text-align: right;
          padding: 0;
          margin: 0;
          z-index: 3;
          .anticon {
            color: inherit;
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
      .btn-option {
        display: block;
        position: absolute;
        left: 0;
        right: auto;
        top: 0;
        bottom: auto;
        width: calc(100% - 24px);
        height: 100%;
        color: $body-color;
        background: none;
        border: 0;
        outline: 0;
        box-shadow: none;
        transition: none;
        padding-right: 7px;
        .invisible {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          width: 0;
          height: 0;
        }
      }
      .btn-option {
        &:hover {
          color: $primary;
        }
        &:after {
          display: none;
        }
        &.ant-dropdown-open {
          opacity: 1;
        }
      }
    }
    .ant-tree-treenode {
      &:hover .btn-dots-option {
        opacity: 1;
      }
      &.ant-tree-treenode-draggable.dragging &:after {
        bottom: 0 !important;
        background: #fff !important;
        border-color: rgba($primary, 0.2) !important;
        box-shadow: inset 0 0 6px 1px rgba($primary, 0.09) !important;
      }
      &.ant-tree-treenode-selected {
        &.drop-target.drag-over {
          background: rgba($primary, 0.12) !important;
          box-shadow: inset 0 0 6px 1px rgba($primary, 0.2) !important;
        }
        &:hover {
          background: rgba($primary, 0.05);
          .btn-dots-option {
            color: $primary;
            &:hover {
              color: darken($primary, 10%);
            }
          }
        }
        &::before {
          display: none;
        }
        &.ant-tree-treenode-checkbox-checked {
          background: none;
        }
      }
      &.drop-target.drag-over {
        background: rgba($primary, 0.12) !important;
        box-shadow: inset 0 0 6px 1px rgba($primary, 0.2) !important;
      }
      &.drop-target .ant-tree-node-content-wrapper {
        overflow: visible !important;
      }
      &.hide-ant-tree-switcher .ant-tree-switcher {
        display: none !important;
      }
      &.hide-ant-tree-draggable-icon .ant-tree-draggable-icon {
        display: none !important;
      }
      &.hide-drag-over-gap-top-bottom.drag-over-gap-top,
      &.hide-drag-over-gap-top-bottom.drag-over-gap-bottom {
        .ant-tree-drop-indicator {
          display: none !important;
        }
      }
    }
    &.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
      color: $primary;
    }
  }
  &.no-btn-option .ant-tree .ant-tree-treenode .ant-tree-node-content-wrapper {
    padding-right: 0;
  }
  .ant-select .ant-input {
    &:-ms-input-placeholder {
      font-style: italic;
    }
    &:-moz-placeholder {
      font-style: italic;
    }
    &::-moz-placeholder {
      font-style: italic;
    }
    &::-webkit-input-placeholder {
      font-style: italic;
    }
  }
  .c-basic-progress-bar {
    position: static;
  }
}
.c-tree-option-dropdown .ant-dropdown-menu-item {
  min-width: 180px;
}

.c-tree-option-dropdown.slide-up-leave {
  display: none !important;
}
