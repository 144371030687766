.auto-scroll-style-1 {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 103px);
}
.auto-scroll-layout-style-1 {
  .ant-layout-sider.c-left-sidebar {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 103px);
  }
  .ant-layout-content.main-content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 103px);
  }
}
.d-block {
  display: block !important;
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-space-between {
  justify-content: space-between !important;
}
.d-inline-block {
  display: inline-block !important;
}
.invisible {
  visibility: hidden !important;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-absolute-center {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.w-auto {
  width: auto !important;
}
.h-auto {
  height: auto !important;
}
.w-100 {
  width: 100% !important;
}
.w-200 {
  width: 200px !important;
}
.min-w-80 {
  min-width: 80px !important;
}
.min-w-100 {
  min-width: 100px !important;
}
.min-w-120 {
  min-width: 120px !important;
}
.min-w-150 {
  min-width: 150px !important;
}
.min-w-200 {
  min-width: 200px !important;
}
.min-w-175-xl {
  @include mq(xl) {
    min-width: 175px !important;
  }
}
.max-w-100-percent {
  max-width: 100% !important;
}
.max-w-200 {
  max-width: 200px !important;
}
.max-w-250 {
  max-width: 250px !important;
}
.max-w-300 {
  max-width: 300px !important;
}
.max-w-400 {
  max-width: 400px !important;
}
.max-w-500 {
  max-width: 500px !important;
}
.max-w-700 {
  max-width: 700px !important;
}
.max-w-900 {
  max-width: 900px !important;
}
.h-100 {
  height: 100% !important;
}
.min-h-22 {
  min-height: 22px !important;
}
.min-h-200 {
  min-height: 200px !important;
}
.font-sim-sun {
  font-family: SimSun, sans-serif !important;
}
.transition {
  transition: all 0.4s ease !important;
}
.transition-none {
  transition: none !important;
}
.text-body {
  color: $body-color !important;
}
.text-primary {
  color: $primary !important;
}
.text-success {
  color: $success !important;
}
.text-warning {
  color: $warning !important;
}
.text-danger {
  color: $danger !important;
}
.text-yellow-star {
  color: $yellow-star !important;
}
.text-orange {
  color: $orange !important;
}
.text-skyblue {
  color: $skyblue !important;
}
.text-green {
  color: $green !important;
}
.text-gray {
  color: #b0b0b0 !important;
}
.text-gray-2 {
  color: #888 !important;
}
.text-white {
  color: #fff !important;
}
.text-note {
  color: #888 !important;
}
.text-hover-primary {
  transition: all 0.4s ease;
  &:hover {
    color: $primary !important;
  }
  &[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
.text-hover-dark-primary {
  transition: all 0.4s ease;
  &:hover {
    color: darken($primary, 10%) !important;
  }
  &[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
.text-hover-danger {
  transition: all 0.4s ease;
  &:hover {
    color: $danger !important;
  }
  &[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
.text-hover-black {
  transition: all 0.4s ease;
  &:hover {
    color: #000 !important;
  }
  &[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
.text-underline {
  text-decoration: underline !important;
}
.text-hover-underline {
  &:hover {
    text-decoration: underline !important;
  }
}
.text-hover-underline-none {
  &:hover {
    text-decoration: none !important;
  }
}
.text-placeholder {
  color: #bfbfbf !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-md-right {
  @include mq(md) {
    text-align: right !important;
  }
}
.text-center {
  text-align: center !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-pre-line {
  white-space: pre-line !important;
}
.text-italic {
  font-style: italic !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-has-right-line {
  overflow: hidden;
  span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-right: 15px;
    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      width: 9000px;
      height: 1px;
      background: $border-color;
    }
  }
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-line-through {
  text-decoration: line-through !important;
}
.text-unassigned {
  white-space: nowrap;
  .anticon {
    vertical-align: -0.2em;
    font-size: 1.13rem;
    color: #b0b0b0;
  }
}
.text-break-all {
  word-break: break-all !important;
}
.text-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@for $i from 0 through 20 {
  .font-size-#{6 + $i * 2} {
    font-size: 6px + $i * 2 !important;
  }
}
.font-weight-normal {
  font-weight: normal !important;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-bold {
  font-weight: bold !important;
}
.color-inherit {
  color: inherit !important;
}
.line-height-1 {
  line-height: 1 !important;
}
.bg-primary {
  background: $primary !important;
}
.bg-white {
  background: #fff !important;
}
.bg-transparent {
  background: transparent !important;
}
.import-status-update {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  background: #fcf1bc;
  border: 1px solid #cdaa37;
  border-radius: 5px;
  text-align: center;
}
.import-status-create {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  background: #f6ffed;
  border: 1px solid #71ba71;
  border-radius: 5px;
  text-align: center;
}
.update {
  background-color: #fcf1bc;
  border: 1px solid #cdaa37;
  border-radius: 5px;
}
.create {
  background-color: #f6ffed;
  border: 1px solid #71ba71;
  border-radius: 5px;
}
.bg-deleted {
  background: #ffe7eb !important;
}
.bg-modified {
  background: #deffdd !important;
}
.bg-old-version {
  background: #fcf1bc !important;
}
.bg-new-version {
  background: #bfe2dd !important;
}
.bg-detail-deleted {
  text-decoration: line-through !important;
}
.bg-detail-modified {
  background: #92cdc6 !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.cursor-move {
  cursor: move !important;
}
.cursor-text {
  cursor: text !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-initial {
  cursor: initial !important;
}
.border-transparent {
  border-color: transparent !important;
  > .ant-btn {
    border-color: transparent !important;
  }
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-1 {
  border: 1px solid $border-color !important;
}
.border-primary {
  border-color: $primary !important;
}
.border-top-1 {
  border-top: 1px solid $border-color !important;
}
.border-bottom-1 {
  border-bottom: 1px solid $border-color !important;
}
.border-left-1 {
  border-left: 1px solid $border-color !important;
}
.border-right-1 {
  border-right: 1px solid $border-color !important;
}
.border-right-lg-1 {
  @include mq(lg) {
    border-right: 1px solid $border-color !important;
  }
}
.outline-0 {
  outline: 0 !important;
}
.box-shadow-none {
  box-shadow: none !important;
}
.border-radius-0 {
  border-radius: 0 !important;
}
.over {
  transition: all 0.4s ease;
  &:hover {
    opacity: 0.7;
  }
}
.list-style-none {
  list-style: none !important;
}
.vertical-align-top {
  vertical-align: top !important;
}
.vertical-align-bottom {
  vertical-align: bottom !important;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

// ------------------------- Spacing
// // Example
// .mb-1 {
//   margin-bottom: 0.25rem !important;
// }
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}
.mx-auto,
.ml-auto {
  margin-left: auto !important;
}
.mx-auto,
.mr-auto {
  margin-right: auto !important;
}
.textarea-resize-none {
  &,
  textarea {
    resize: none !important;
  }
}
.box-top-action-on-detail-page {
  position: absolute;
  right: 0;
  top: 0;
}
.overlay-no-event {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
.cicle-number-timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  color: $primary;
  border: 2px solid $primary;
}
.box-code-style {
  display: flex;
  flex-wrap: wrap;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  .ant-typography {
    flex: 0 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #d9d9d9;
    background: #fafafa;
    margin: 0;
  }
  .box-code {
    flex: 0 0 calc(100% - 32px);
    white-space: pre-wrap;
    color: #fff;
    background: #0c0c0c;
    padding: 15px;
    margin: 0;
    code {
      padding: 0;
      margin: 0;
    }
  }
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: $primary;
  &:hover,
  &:focus {
    color: darken($primary, 10%);
  }
}
.ant-timeline-item-content {
  margin-left: 40px;
}
.list-style-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    position: relative;
    padding-left: 24px;
    .txt-num {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0.6em;
      width: 13px;
      height: 13px;
      font-weight: bold;
      font-size: 10px;
      color: $primary;
      border: 1px solid $primary;
      border-radius: 2px;
      &:before {
        content: '';
        position: absolute;
        left: -3px;
        top: 3px;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        border-left: 1px solid $primary;
        border-bottom: 1px solid $primary;
      }
    }
  }
}
.dot-status-style {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #b9b9b9;
}
.style-disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.25) !important;
  user-select: none;
}
.full-screen-common {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  background: #fff !important;
  overflow-y: auto !important;
  z-index: 1000 !important;
}
.disabled-link {
  pointer-events: none;
}
.hide-next-sibling + * {
  display: none !important;
}
.ant-spin-nested-loading.blur-100 {
  > div > .ant-spin {
    z-index: 20;
  }
  .ant-spin-container::after {
    opacity: 1;
  }
}
.card-common {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.effect-fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}
.effect-flash {
  -webkit-animation-name: flash;
  animation-name: flash;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.external-system-label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 41px;
  height: 19px;
  font-size: 12px;
  border-radius: 5px 5px 5px 0;
  color: #fff;
  background: $skyblue;
  &.jira {
    color: #fff;
    background: $jira-color;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 100%);
    border-left: 6px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid $jira-color;
    clear: both;
  }
}
