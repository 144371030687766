@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-error-file-not-found {
  overflow: hidden;
  max-width: 838px;
  padding: 0 0 30px;
  margin: auto;
  .box-detail {
    padding: 30px 0;
  }
  .txt-error {
    font-weight: bold;
    font-size: 32px;
    color: #383838;
    margin-bottom: 8px;
    @include mq(max_sm) {
      font-size: 28px;
    }
  }
  .txt-desc {
    font-size: 16px;
    line-height: 1.3;
    color: #606060;
    @include mq(max_sm) {
      font-size: 14px;
    }
  }
  .img-file-not-found {
    @include mq(max_sm) {
      max-width: 450px;
    }
  }
}