@import '../../assets/scss/_variables.scss';
@import '../../assets/scss/_mixins.scss';

.c-related-to-relationship-modal {
  .create-ticket-type {
    .ant-select-selector {
      border: none;
      box-shadow: none !important;
      padding-left: 0;
      background: $background-color !important;
      border-radius: 5px;
    }
    .ant-form-item-label {
      label {
        background: $background-color !important;
        border-radius: 5px;
        padding-left: 5px;
      }
    }
  }
}
