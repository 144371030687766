@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-dropdown-language-dropdown {
  .ant-dropdown-menu {
    padding: 7px 0;
  }
  .ant-dropdown-menu-item {
    min-width: 60px;
    text-align: center;
    padding: 7px 12px;
    a {
      line-height: 1;
    }
  }
  .anticon {
    margin: 0 !important;
  }
  svg {
    width: 26px;
    height: 19px;
    border: 1px solid #ececec;
  }
}

.c-language-dropdown {
  .btn-flag {
    display: inline-flex;
    align-items: center;
    .ic-flag {
      .anticon {
        vertical-align: middle;
      }
      svg {
        width: 26px;
        height: 19px;
        border: 1px solid #ececec;
      }
    }
  }
}
