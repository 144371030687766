.ant-tabs {
  color: $body-color;
  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: $body-color;
      transition: all 0.4s ease;
      > * {
        color: inherit;
      }
    }
    .ant-tabs-tab-btn:hover {
      color: $primary;
    }
  }
  .ant-tabs-tab {
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $primary;
      }
    }
    &.ant-tabs-tab-disabled {
      .ant-tabs-tab-btn {
        color: rgba(0, 0, 0, 0.25) !important;
        cursor: not-allowed;
      }
    }
  }

  > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
}
.ant-tabs-ink-bar {
  background: $primary;
}
.ant-tabs {
  &.ant-tabs-nav-mb-2 .ant-tabs-nav {
    margin-bottom: 0.5rem;
  }
  &.ant-tabs-nav-mb-1 .ant-tabs-nav {
    margin-bottom: 0.25rem;
  }
  &.tabs-link {
    .ant-tabs-tab {
      padding: 0;
      .ant-tabs-tab-btn {
        > * {
          display: block;
          background: none;
          border: 0;
          outline: 0;
          padding: 12px 0;
        }
      }
      .ant-tabs-tab-btn:hover {
        color: $primary;
      }
    }
  }
}
.ant-tabs-card {
  .ant-tabs-nav {
    padding-left: 15px;
  }
  > .ant-tabs-nav .ant-tabs-tab,
  > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 5px 16px;
    background: #f3f3f3;
    border: 1px solid #e2e2e2;
  }
  > .ant-tabs-nav .ant-tabs-tab-active,
  > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #fff;
  }
  &.ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  &.ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #e2e2e2;
  }
  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  &.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #fff;
  }
}
.ant-tabs-tab-pt-0 {
  .ant-tabs-tab {
    padding-top: 0;
  }
}
.ant-tabs-nav-p-0 {
  .ant-tabs-nav {
    padding: 0;
  }
}
.ant-tabs-nav-p-0-m-0 {
  .ant-tabs-nav {
    padding: 0;
    margin: 0;
  }
}
