.collapse-item {
  .btn-toggle {
    position: relative;
    overflow: hidden;
    > span {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      cursor: pointer;
      background: #fff;
      padding-right: 5px;
      padding-left: 16px;
      &:before {
        content: '';
        position: absolute;
        left: 100%;
        top: 50%;
        height: 1px;
        width: 1000vw;
        background: $border-color;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
    .anticon {
      position: absolute;
      left: 0;
      top: 0.6em;
      font-size: 0.8em;
    }
  }
  .collapse-body {
    display: none;
    &.show {
      display: block;
      padding-left: 16px;
    }
  }
}
.ant-collapse {
  &.hide-collapse-header {
    .ant-collapse-header {
      display: none;
    }
  }
  &.collapse-content-box-p-0 {
    .ant-collapse-content-box {
      padding: 0;
    }
  }
}
