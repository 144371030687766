.anticon {
  &.ant-notification-notice-icon-success {
    color: #52c41a;
    color: $success;
  }
  &.ant-notification-notice-icon-warning {
    color: $warning;
  }
  &.ant-notification-notice-icon-error {
    color: $danger;
  }
  &.gray {
    color: rgba(0, 0, 0, 0.45);
  }
  &.ic-bug-searching {
    path,
    circle,
    line {
      fill: none;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
  }
  svg {
    width: 1em;
    height: 1em;
  }
}
