.ant-btn {
  border-radius: 5px !important;
  &:hover,
  &:focus {
    color: $primary;
    border-color: $primary;
  }
  &.no-border-on-focus {
    &:focus:not(:hover) {
      border-color: transparent !important;
    }
  }
  &.ant-btn-xl {
    height: 50px;
    padding: 9.8px 15px;
    font-size: 18px;
    border-radius: 2px;
  }
  &.no-animation[ant-click-animating-without-extra-node]:after {
    display: none !important;
  }
}
.ant-btn-icon-only {
  .anticon-loading {
    margin-right: 0 !important;
  }
}
.ant-btn-circle {
  border-radius: 50% !important;
}
.ant-btn-primary {
  background: $primary;
  border-color: $primary;
  &:hover,
  &:focus {
    color: #fff;
    background: darken($primary, 15%);
    border-color: darken($primary, 15%);
  }
  &.success {
    background: $success;
    border-color: $success;
    &:hover,
    &:focus {
      color: #fff;
      background: darken($success, 15%);
      border-color: darken($success, 15%);
    }
  }
}
.ant-btn-primary.btn-light-primary {
  color: $body-color;
  background: lighten($primary, 56%);
  border-color: lighten($primary, 56%);
  &:hover,
  &:focus {
    color: $body-color;
    background: lighten($primary, 50%);
    border-color: lighten($primary, 50%);
  }
}
.ant-btn-link {
  color: $primary;
  &:hover,
  &:focus {
    color: $primary;
    border-color: $primary;
  }
}
.btn-gray {
  border: none;
  background: #f0f2f5;
  color: $body-color;
  &:hover,
  &:focus {
    color: $body-color;
    background: #dfdfdf;
  }
}
.ant-btn-background-ghost.ant-btn-primary {
  color: $primary;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    color: darken($primary, 15%);
    border-color: darken($primary, 15%);
  }
}
.ant-btn.ant-btn-ghost.ant-btn-dangerous {
  &:hover,
  &:focus {
    color: red;
    border-color: red;
  }
}
.btn-reload-dark {
  width: auto;
  height: auto;
  line-height: 1;
  color: $body-color;
  border: 0;
  .anticon {
    color: inherit;
  }
}
.btn-collapse-expand {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  width: 30px;
  .anticon {
    transform-origin: center center;
    transform: rotate(90deg);
    &.up {
      transform: rotate(-90deg);
    }
  }
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  margin-right: 8px;
}
a.ant-btn-round.ant-btn-lg {
  padding: 0 20px;
}
.btn-reload-on-modal {
  padding: 0;
  width: auto;
  height: auto;
  color: rgba(0, 0, 0, 0.45);
  border: 0;
  z-index: 2;
  &:hover {
    color: $primary;
  }
}
.btn-link-to-defect {
  &.has-defect {
    color: $orange !important;
    border-color: $orange !important;
  }
}
.btn-prev-item,
.btn-next-item {
  width: auto;
  height: auto;
  font-size: 1.1em;
  color: $body-color;
  border-color: transparent !important;
  padding: 0;
  &:last-child {
    margin-left: 2px;
  }
  &:hover {
    color: $primary;
  }
  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
  }
}
.btn-reload-on-detail-page {
  position: static;
  width: auto;
  height: auto;
  color: rgba(0, 0, 0, 0.45);
  border: 0;
  padding: 0;
  &:hover {
    color: $primary;
  }
}
.btn-cancel-modal {
  border: none;
  &:nth-child(1) {
    background: #f0f2f5;
    color: #3d3c3c;
    &:hover {
      background: #e6e8ea;
    }
  }
}

.btn-toggle-expand {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible;
  width: 24px;
  height: 24px;
  color: $primary;
  background: none !important;
  border: 0 !important;
  border-radius: 50% !important;
  padding: 0 !important;
  z-index: 2;
  &:not([disabled]):hover {
    color: darken($primary, 10%) !important;
    background: rgba($primary, 23%) !important;
  }
  .ic-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
  }
}
[class*='ant-tooltip'].border-0 > .ant-btn {
  border: 0 !important;
}
[class*='ant-tooltip'].bg-transparent > .ant-btn {
  background: transparent !important;
}
.btn-close-minimize {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.4s ease;
  &:hover {
    color: rgba(0, 0, 0, 0.75);
  }
}
.btn-minimized {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 0;
  transform: translate(100%, 0);
  overflow: visible;
  z-index: 99;
  width: 300px;
  height: 48px;
  background: #fff;
  color: $primary;
  font-size: 16px;
  outline: 0;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.2509803922);
  text-align: left;
  cursor: pointer;
  transition: all 0.4s ease;
  padding: 8px 15px;
  &:hover {
    background: #fbf3f3;
    .btn-close-minimize {
      opacity: 1;
    }
  }
  &.animated {
    right: 20px;
    transform: translate(0, 0);
  }
}
