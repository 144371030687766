.c-work-item-field {
  &.type-relation,
  &.type-suggestion {
    .ant-form-item-extra {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
  .ant-form-item-has-error {
    .ant-form-item-control {
      height: auto !important;
    }
  }
}
