.ant-pagination-item-active {
  border-color: $primary;
  a {
    color: $primary;
  }
}
.ant-pagination-item {
  border-radius: 5px !important;
}
.ant-pagination-item {
  &:focus,
  &:hover {
    border-color: $primary;
    a {
      color: $primary;
    }
  }
  &.ant-pagination-item-active {
    border-radius: 5px !important;
  }
}
.ant-pagination-item-link {
  border-radius: 5px !important;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}
.ant-pagination-prev,
.ant-pagination-next {
  &:hover button {
    border-color: $primary;
  }
  &:focus,
  &:hover {
    .ant-pagination-item-link {
      color: $primary;
      border-color: $primary;
    }
  }
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $primary;
}
.ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
}
