@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-editor {
  .rdw-editor-toolbar {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    margin-bottom: -1px;
  }
  .DraftEditor-root {
    background: #fff;
  }
  .DraftEditor-editorContainer {
    border: 1px solid #f1f1f1;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .public-DraftEditor-content {
    min-height: 200px;
    padding: 9px;
    margin-top: -0.6em;
  }
  .public-DraftEditorPlaceholder-inner {
    padding: 15px 10px;
  }
  .rdw-link-modal,
  .rdw-embedded-modal,
  .rdw-colorpicker-modal {
    height: auto;
  }
  .rdw-colorpicker-modal-options,
  .rdw-dropdown-optionwrapper {
    overflow: auto;
  }
  .rdw-colorpicker-modal-style-label,
  .rdw-embedded-modal-header-option {
    white-space: nowrap;
  }
  .rdw-option-wrapper {
    height: 30px;
    min-width: 30px;
  }
  .rdw-option-active {
    background: #f1f1f1;
  }
  .rdw-block-dropdown,
  .rdw-fontfamily-dropdown {
    width: auto;
    min-width: 0;
    .rdw-dropdown-optionwrapper {
      min-width: 140px;
    }
  }
  .rdw-dropdown-selectedtext {
    white-space: nowrap;
    padding-right: 23px;
  }
  .rdw-dropdown-carettoopen {
    right: 6px;
  }
  .rdw-fontfamily-placeholder {
    max-width: 100%;
  }
  .rdw-image-alignment-options-popup {
    display: none;
    width: auto;
  }
  .rdw-suggestion-dropdown {
    width: 400px;
  }
  .rdw-suggestion-dropdown span[data-index]:hover {
    background-color: #f1dbdb;
  }
}
.c-basic-editor {
  &.is-read-only {
    .rdw-editor-main {
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
    }
    .DraftEditor-editorContainer {
      border-radius: 5px !important;
    }
  }
}
