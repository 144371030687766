@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-history {
  .btn-reload-on-detail-page {
    position: absolute;
    right: 0;
    top: -46px;
    z-index: 2;
  }
  .box-history-list {
    transition: all 0.4s ease;
  }
  .ant-collapse {
    background: none;
    border-top: 1px solid $border-color;
  }
  .ant-collapse-item {
    padding: 10px;
    &:hover {
      background: #f3f3f3;
    }
    .ant-collapse-header {
      display: flex;
      padding: 0;
      .ant-collapse-arrow {
        top: 7px;
        left: 0;
        vertical-align: 0;
        font-size: 0.7em;
        margin-right: 6px;
      }
    }
  }
  .panel-content {
    display: flex;
  }
  .panel-value {
    display: flex;
    align-items: center;
    overflow-y: auto;
  }
  .ant-collapse-content-box {
    padding-left: 60px;
  }
  .c-basic-upload-multiple-files {
    padding: 10px;
    .c-attachment-list .item {
      width: 100px;
      .box-file {
        height: 100px;
      }
    }
  }
}
