@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-test-result-status-chart {
  .range-date-selection {
    position: absolute;
    right: 0;
    top: -39px;
    min-width: 150px;
    text-align: left;
  }
  .txt-total {
    font-weight: 500;
    font-size: 20px;
    color: #7c7c7c;
    margin-top: 20px;
    .txt-num {
      font-size: 1.2em;
      line-height: 1;
    }
  }
}
