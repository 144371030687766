@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-status-label {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  line-height: 1;
  .status-label {
    display: block;
    font-weight: bold;
    font-size: 11px;
    line-height: 1.55;
    color: #163871;
    background: #dadada;
    border: 0;
    border-radius: 3px;
    text-transform: uppercase;
    text-align: center;
    box-shadow: none;
    white-space: nowrap;
    padding: 0 5px;
  }
}
.ant-select .ant-select-selection-item {
  .c-status-label {
    margin-top: -0.3em;
  }
}
