.react-flow {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  .react-flow__handle {
    &.target {
      z-index: 1;
    }
    &.source {
      z-index: 2;
    }
  }
  &.is-read-only {
    .react-flow__handle {
      opacity: 0 !important;
      pointer-events: none;
    }
  }
  &.is-connecting {
    .react-flow__handle {
      &.target {
        z-index: 2;
      }
      &.source {
        z-index: 1;
      }
    }
  }
  .react-flow__node-default,
  .react-flow__node-group,
  .react-flow__node-input,
  .react-flow__node-output,
  .react-flow__node-customNode {
    width: auto;
    min-width: 105px;
    font-weight: bold;
    font-size: 12px;
    color: #222;
    background: #fff;
    border: 0;
    border-radius: 3px;
    text-transform: uppercase;
    text-align: center;
    box-shadow: none;
    padding: 5px 20px;
  }
  .react-flow__node {
    box-shadow: none !important;
    .react-flow__handle {
      opacity: 0;
      height: 8px;
      width: 8px;
    }
    &:hover {
      .react-flow__handle {
        opacity: 1;
      }
    }
    &.selected {
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.38) !important;
    }
    &:not(.selectable) {
      cursor: pointer;
    }
  }
  .react-flow__edge-text {
    font-size: 11px;
  }
  .react-flow__edge {
    &:hover {
      .react-flow__edge-path {
        stroke: #555;
      }
    }
    &.selected {
      .react-flow__edge-text {
        font-weight: 500;
      }
    }
  }
  .react-flow__edge-textwrapper {
    cursor: pointer;
  }
  .react-flow__controls {
    left: 15px;
    bottom: 15px;
  }
}
