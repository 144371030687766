@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-preview-file {
  .box-preview-file {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100vw;
    height: 100vh;
    background: #2f3030;
    overflow: hidden;
    .box-header {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 2;
      height: 60px;
      background: #2f3030;
      box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
      padding: 0 120px;
      .box-name {
        font-size: 16px;
        color: #fff;
      }
      .box-action {
        display: flex;
        align-items: center;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        .ant-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border: 0;
          background: transparent;
          &:hover {
            background: #6d6d6d;
          }
        }
      }
    }
    .c-view-file,
    .c-react-pan-zoom-image,
    .pan-container {
      height: calc(100vh - 60px);
    }
    .pan-container img {
      max-height: calc(100vh - 60px);
    }
    .c-view-file {
      &.type-audio {
        padding: 15px;
      }
    }
    .c-other-file {
      &.only-preview-file-on-client {
        .ic-file {
          font-size: 72px;
        }
      }
      .box-other-file {
        width: 100%;
        max-width: 500px;
        background: rgb(209 209 209 / 38%);
        border-radius: 15px;
        text-align: center;
        padding: 40px 30px;
        box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
        .txt {
          font-size: 22px;
          color: #fff;
          @include mq(max_sm) {
            font-size: 22px;
          }
        }
      }
    }
    .pan-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .box-video-player {
      width: 100%;
      height: 100%;
    }
  }
}
