@import '../assets/scss/variables';
@import '../assets/scss/mixins';

.ant-layout {
  background: #f2f4f7;
}
.main-content {
  position: relative;
}
.l-default {
  .ant-layout.ant-layout-has-sider > .ant-layout,
  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: initial !important;
  }
  .main-body {
    min-height: calc(100vh - #{$header-height});
  }
}

.l-home {
  .main-body {
    min-height: calc(100vh - #{$header-height});
  }
}

.l-blank {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .btn-flag {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
  }
}
