@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-selected-row-actions-with-animation {
  position: relative;
  overflow: hidden;
  .box-transform {
    position: relative;
    overflow: hidden;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transform: translate(-100%, 0);
    transition: all 0.4s ease;
    padding-left: 8px;
    .ant-btn {
      border: 0;
      border-left: 1px solid #d9d9d9;
      border-radius: 0 !important;
    }
  }
}
.c-selected-row-actions-with-animation.animated {
  .box-transform {
    transform: translate(0, 0);
  }
}
