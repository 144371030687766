@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-column-chooser {
  .btn-column-chooser {
    width: auto;
    height: auto;
    line-height: 1;
    color: $body-color;
    border: 0;
    .anticon {
      color: inherit;
    }
  }
}

.c-column-chooser-dropdown {
  .ant-dropdown-menu {
    max-height: 340px;
    overflow-y: auto;
  }
  .ant-dropdown-menu-item {
    min-width: 180px;
    &:not(.has-padding) {
      padding: 0;
    }
    .ant-checkbox-wrapper {
      padding: 5px 12px;
      span {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }
}
