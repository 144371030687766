@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-main-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: $header-height;
  line-height: normal;
  background: #fff;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
  padding: 0 15px;
  z-index: 10;
  &.beta-version {
    padding-left: 30px;
    .label-beta {
      display: flex !important;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 21px;
      top: 10px;
      height: 20px;
      width: 150px;
      font-size: 12px;
      background: #f00;
      color: #fff;
      transform-origin: center center;
      transform: translate(-50%, 0px) rotate(-45deg);
    }
  }
  .logo {
    img {
      width: 156px;
      height: auto;
    }
  }
  .box-product-menu {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    list-style: none;
    height: 100%;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 30px;
      &.active a,
      a:hover {
        color: $primary;
        &:after {
          opacity: 1;
        }
      }
      a {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        color: $body-color;
        text-decoration: none;
        &:after {
          opacity: 0;
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: $primary;
          transition: all 0.4s ease;
        }
      }
    }
  }
  .btn-dropdown-account {
    height: 32px;
    background: #fff;
    border: 1px solid #eaecf0;
    border-radius: 6px;
    padding: 0 10px;
    .c-user-avatar {
      .txt-name {
        font-weight: 500;
        color: #344054;
        margin-left: 3px;
      }
    }
    .ic-arrow {
      margin-left: 5px;
    }
  }
}

.c-box-project-action {
  .box-filter-btns {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        font-weight: normal;
        font-size: 14px;
        color: #344054;
        background: transparent;
        border: 0;
        outline: 0;
        border-radius: 5px;
        white-space: nowrap;
        padding: 0;
        transition: none;
        &.active {
          font-weight: 500;
          color: #fff;
          background: #0c111d;
          padding: 0 12px;
        }
        .txt-count {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          min-width: 18px;
          font-weight: 500;
          font-size: 12px;
          color: #344054;
          background: #fff;
          border: 1px solid #d0d5dd;
          outline: 0;
          border-radius: 10px;
          padding: 0 5px;
          margin-left: 8px;
        }
      }
    }
  }
  .search-project-input {
    width: 228px;
    height: 32px;
    @include mq(max_lg) {
      display: none;
    }
    input {
      &:-ms-input-placeholder {
        color: #778093;
      }
      &:-moz-placeholder {
        color: #778093;
      }
      &::-moz-placeholder {
        color: #778093;
      }
      &::-webkit-input-placeholder {
        color: #778093;
      }
    }
    .anticon-search {
      font-size: 18px;
      color: #778093;
    }
  }
  .btn-dropdown-search-input-filter {
    display: none;
    @include mq(max_lg) {
      display: block;
    }
  }
  .btn-dropdown-user-exists-in-project-filter {
    width: 32px;
    padding-left: 0;
    padding-right: 0;
    .ic-filter svg {
      font-size: 17px;
    }
    .ic-remove {
      position: absolute;
      right: -5px;
      top: -5px;
      font-size: 12px;
      color: #a0a0a0;
      background: #fff;
      border-radius: 50%;
      transition: all 0.4s ease;
    }
  }
}

.dropdown-user-exists-in-project-filter {
  .ant-dropdown-menu-item {
    padding: 3px 7px;
    &:hover {
      .btn-remove {
        opacity: 1;
        visibility: visible;
      }
    }
    &.active {
      .ant-dropdown-menu-title-content {
        color: $primary;
        background: rgba($primary, 0.05);
      }
    }
    .ant-dropdown-menu-title-content {
      min-width: 200px;
      font-weight: 500;
      border-radius: 5px;
      padding: 5px 25px 5px 10px;
    }
    .btn-remove {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translate(0, -50%);
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
      transition: all 0.4s ease;
    }
  }
}

.left-header-dropdown {
  min-width: 160px !important;
  max-width: 300px !important;
  .ant-dropdown-menu-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.select-option-on-header {
  min-width: 160px !important;
  max-width: 300px !important;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a {
  color: #fff;
}
