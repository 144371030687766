.children-table-on-expanded-row tr.is-editing-row .field-wrapper.html-field .cell-text.is-editable-field {
  overflow: hidden !important;
  align-items: flex-start !important;
  height: 142px !important;
  padding-top: 5px !important;
}
.row-dragging-ticket-on-editable-cell-table {
  .ant-table-cell {
    display: table-cell !important;
    vertical-align: middle !important;
    background: none !important;
    padding: 4px 10px !important;
  }
  .ant-table-cell {
    &.left-utils-column {
      width: 15px;
      text-align: center;
    }
    &.action {
      display: none !important;
    }
  }
  .ant-table-row-expand-icon-cell,
  .ant-table-selection-column,
  .btn-go-to-detail-wrapper {
    display: none !important;
  }
}
.editable-cells-table {
  table {
    .ant-table-row-expand-icon-cell > * {
      margin-left: 25px;
    }
    colgroup .ant-table-selection-col {
      width: 60px;
      min-width: 60px;
    }
    .ant-table-thead > tr > th.ant-table-column-has-sorters {
      padding-left: 8px;
    }
    .ant-table-tbody > tr > td {
      overflow: visible;
      padding: 0;
    }
    .ant-table-row {
      position: relative;
      &:hover {
        .btn-drag {
          opacity: 1;
        }
        .btn-go-to-detail-wrapper {
          opacity: 1;
        }
      }
      &.is-new-row {
        .ant-table-cell {
          vertical-align: top;
        }
        .ant-table-cell.ant-table-row-expand-icon-cell {
          vertical-align: middle;
        }
        .ant-table-cell.action > * {
          align-items: center;
          min-height: 42px;
          padding: 5px 8px;
        }
      }
    }
  }
  .ant-spin-blur table .ant-table-tbody > tr > td:not(.ant-table-selection-column) > * {
    opacity: 0.3;
  }
  .c-editable-cell {
    position: relative;
    .field-wrapper .ant-picker {
      width: 100%;
    }
    .field-wrapper.key-field {
      min-width: 110px;
    }
    .field-wrapper.attachments-field {
      padding: 5px 8px;
      .ant-form-item-control-input {
        min-height: 0;
      }
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .field-wrapper.is-new .ant-form-item {
      padding: 5px 8px;
    }
    .cell-text {
      display: flex;
      align-items: center;
      position: relative;
      height: 33px;
      width: 100%;
      background: none !important;
      padding: 8px;
      .c-status-label {
        display: block;
      }
    }
    .cell-text {
      &.hide-after:after {
        display: none;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
    .trigger-focus-element.focused {
      & + .field-wrapper {
        .ant-input-affix-wrapper,
        .ant-input,
        .ant-select-selector,
        .ant-picker,
        .box-visual-input .cell-text,
        .ant-upload.ant-upload-drag {
          background: #eff8ff !important;
          border: 1px solid #40a9ff !important;
          box-shadow: inset 0 0 0 1px #40a9ff !important;
        }
        .ant-input-affix-wrapper {
          .ant-input {
            border: 0 !important;
            box-shadow: none !important;
            background: none !important;
          }
        }
        .ant-select-clear,
        .ant-picker-clear {
          border-radius: 50%;
        }
        .box-visual-input .cell-text {
          border-radius: 5px !important;
        }
      }
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      white-space: normal;
    }
    .cell-text-value {
      position: relative;
    }
    .allow-click {
      z-index: 3;
    }
    .allow-click.relation-link {
      z-index: initial;
    }
    .field-wrapper:not(.tag-field):not(.suggestion-field) .ant-select-selection-search-input {
      caret-color: transparent !important;
    }
  }
  .c-editable-cell:hover .btn-external-link-wrapper {
    opacity: 1;
  }
  .btn-action-editable-cell-wrapper {
    display: inline-flex;
    .loading-icon {
      display: none;
    }
    .loading-icon.show-loading {
      display: block;
      & + * {
        display: none;
      }
    }
  }
  .left-utils-column {
    position: static;
    .btn-drag {
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 24px;
      position: absolute;
      left: 3px;
      top: 50%;
      transform: translate(0, -50%);
      transition: none;
      z-index: 2;
    }
  }
  .btn-go-to-detail-wrapper,
  .btn-external-link-wrapper {
    opacity: 0;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .btn-go-to-detail {
    height: 20px;
    line-height: 16px;
    font-size: 12px;
    color: #81807d;
    background: #f6f6f6;
    border: 1px solid #d9d9d9;
    z-index: 2;
    transition: color 0.4s ease, background-color 0.4s ease, border 0.4s ease;
  }
  .btn-go-to-detail:hover:not([disabled]) {
    color: $primary;
    border: 1px solid $primary;
  }
  .btn-external-link {
    width: 20px;
    height: 20px;
    line-height: 16px;
    font-size: 12px;
    color: #81807d;
    background: #f6f6f6;
    border: 1px solid #d9d9d9;
    z-index: 2;
    transition: color 0.4s ease, background-color 0.4s ease, border 0.4s ease;
    padding: 0;
  }
  .btn-external-link:hover:not([disabled]) {
    color: $primary;
    border: 1px solid $primary;
  }
  .children-table-on-expanded-row {
    table {
      .ant-table-thead .ant-table-cell.orderId {
        text-align: center;
      }
      .ant-table-tbody .ant-table-cell.orderId .c-editable-cell .cell-text {
        justify-content: center;
      }
      .btn-drag {
        left: 5px;
        width: 25px;
      }
    }
  }
}
.is-editing-cell.is-editing-test-step-field {
  .c-editable-cell .cell-text {
    pointer-events: none;
  }
  .c-editable-cell:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    pointer-events: none;
    z-index: 10;
  }
  .children-table-on-expanded-row.is-editing-test-step-table {
    .c-editable-cell .cell-text {
      pointer-events: initial;
    }
    .c-editable-cell:after {
      content: '';
      visibility: hidden;
      z-index: -9999;
    }
  }
}
.editable-cells-table {
  &.push-down-pagination .c-basic-table-pagination {
    margin-top: 45px;
  }
  &.has-drag-drop-to-order .ant-table-selection-column {
    padding-left: 12px !important;
    padding-right: 5px !important;
  }
  &.has-external-system {
    colgroup .ant-table-selection-col {
      width: 86px;
      min-width: 86px;
    }
    .ant-table-selection-column {
      padding-left: 61px !important;
      padding-right: 5px !important;
      .ant-checkbox-wrapper {
        width: 21px;
      }
    }
    .left-utils-column .btn-drag {
      left: 43px;
    }
    .external-system-label {
      display: flex;
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
}
.is-editing-cell {
  .btn-action-editable-cell-wrapper {
    cursor: not-allowed !important;
  }
  .btn-action-editable-cell,
  .btn-action-editable-cell:hover {
    pointer-events: none !important;
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: rgba(0, 0, 0, 0.25) !important;
    background: none !important;
    box-shadow: none !important;
  }
  .btn-action-editable-cell.reduce-opacity-when-is-editing-cell {
    opacity: 0.3 !important;
  }
  .ant-table-column-has-sorters {
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
  .ant-table-column-sorter {
    display: none !important;
  }
  .react-resizable-handle {
    display: none !important;
  }
  .btn-drag {
    opacity: 0.3 !important;
  }
  .btn-go-to-detail,
  .btn-external-link {
    opacity: 0.5 !important;
  }
  .editable-cells-table {
    .ant-table-selection-column {
      cursor: not-allowed !important;
      .ant-checkbox-wrapper,
      .ant-checkbox-inner,
      .ant-checkbox-input {
        pointer-events: none !important;
      }
      .ant-checkbox-inner {
        pointer-events: none !important;
        background-color: #f5f5f5 !important;
        border-color: #d9d9d9 !important;
      }
      .ant-checkbox-inner:after {
        opacity: 0.5;
      }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      cursor: not-allowed !important;
    }
    .ant-pagination-item {
      cursor: not-allowed !important;
      border-color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-pagination-item-link,
    .ant-pagination-item a {
      pointer-events: none !important;
      color: rgba(0, 0, 0, 0.25) !important;
      border-color: rgba(0, 0, 0, 0.25) !important;
      background: none !important;
      box-shadow: none !important;
    }
    .ant-pagination-options {
      .ant-select {
        cursor: not-allowed !important;
      }
      .ant-select-selector {
        pointer-events: none !important;
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: rgba(0, 0, 0, 0.25) !important;
        background: none !important;
        box-shadow: none !important;
      }
    }
  }
}
.row-dragging-test-step-on-editable-cell-table {
  .ant-table-cell {
    display: table-cell;
    vertical-align: middle;
    width: 150px;
    background: none !important;
    padding: 4px 10px !important;
    .btn-drag {
      position: absolute;
      left: 3px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  .ant-table-cell {
    &.left-utils-column {
      width: 25px;
      text-align: center;
    }
    &.orderId {
      width: 40px;
    }
    &.ant-table-selection-column,
    &.attachments,
    &.action {
      display: none !important;
    }
  }
}
.update-ticket-status-modal {
  .anticon-exclamation-circle {
    display: none;
  }
  .ant-modal-confirm-content {
    margin: 0;
  }
}
