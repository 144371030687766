@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-user-avatar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  .box-avatar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .ant-avatar {
    vertical-align: -0.3em;
  }
}
.c-user-avatar-popover {
  .ant-popover-inner-content {
    width: 100%;
    padding: 0;
  }
}
.ant-avatar-group .c-user-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-popover-inner-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  max-height: 250px;
  overflow-y: auto;
  .c-user-avatar {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
