.react-resizable-handle {
  overflow: visible;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 16px;
  height: 100%;
  cursor: col-resize;
  &:hover {
    background: #e6e6e6;
  }
  &:before {
    display: none;
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    width: 2000px;
    height: 100%;
    z-index: 2;
  }
  &:active {
    &:before {
      display: block;
    }
  }
}
.react-resizable {
  &.hide-resizable {
    .react-resizable-handle {
      display: none;
    }
  }
}
body.resizing {
  overflow: hidden !important;
}
.resizing {
  &,
  * {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
}
