@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-upload-multiple-files {
  border-radius: 5px;
  .ant-upload {
    &.ant-upload-drag {
      background: #fff;
      border-radius: 5px;
    }
    .ant-upload-btn {
      display: block;
      padding: 15px;
    }
    .ant-upload-drag-container {
      display: block;
    }
    .ant-upload-drag-icon {
      .anticon {
        font-size: 1.2em;
        vertical-align: -3px;
      }
    }
    .txt-drop-file-or-browser {
      padding-left: 5px;
    }
  }
  .box-attachment-list-wrapper {
    text-align: left;
    margin-bottom: -10px;
  }
}
.c-basic-upload-multiple-files.on-table {
  .ant-spin-dot {
    font-size: 16px;
  }
  .ant-upload {
    &.ant-upload-drag {
      min-height: 32px;
    }
    .ant-upload-btn {
      padding: 7.9px 6px;
    }
    .ant-upload-drag-icon {
      font-size: 11px;
      line-height: 1.3;
    }
  }
  .box-attachment-list-wrapper {
    margin-top: 6px !important;
  }
}
