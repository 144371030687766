@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-preview-file-modal {
  width: 100%;
  max-width: 100%;
  .ant-modal-content {
    background: none;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
    text-align: center;
  }
  .btn-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 10px;
    top: calc(50% + 30px);
    transform: translate(0, -50%);
    width: 50px;
    height: 50px;
    font-size: 22px;
    color: #bcbcbc;
    background: rgb(255 255 255 / 5%);
    border: 0;
    border-radius: 50% !important;
    z-index: 999;
    &[disabled] {
      cursor: not-allowed !important;
      color: #606060 !important;
      background: rgb(255 255 255 / 5%) !important;
    }
    &:hover {
      color: #fff;
      background: rgb(255 255 255 / 15%);
    }
  }
  .btn-next {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    top: calc(50% + 30px);
    transform: translate(0, -50%);
    width: 50px;
    height: 50px;
    font-size: 22px;
    color: #bcbcbc;
    background: rgb(255 255 255 / 5%);
    border: 0;
    border-radius: 50% !important;
    z-index: 999;
    &[disabled] {
      cursor: not-allowed !important;
      color: #606060 !important;
      background: rgb(255 255 255 / 5%) !important;
    }
    &:hover {
      color: #fff;
      background: rgb(255 255 255 / 15%);
    }
  }
  .c-basic-preview-file .box-preview-file {
    background: rgb(47 48 48 / 93%);
  }
}
