.ant-tooltip {
  .ant-typography {
    color: #fff;
    code {
      background: #757575;
    }
  }
}
.ant-tooltip {
  &.ant-tooltip-inner-rounded-6 {
    .ant-tooltip-inner {
      border-radius: 8px;
    }
  }
}
.ant-tooltip-disabled-compatible-wrapper {
  &.p-0 > .ant-btn {
    padding: 0 !important;
  }
  &.w-auto > .ant-btn {
    width: auto !important;
  }
  &.h-auto > .ant-btn {
    height: auto !important;
  }
  &.border-transparent > .ant-btn {
    border-color: transparent !important;
  }
  &.bg-transparent > .ant-btn {
    background: transparent !important;
  }
}
.ant-popconfirm {
  &.hide-title {
    .ant-popover-message {
      display: none;
    }
    .ant-popover-buttons {
      .ant-btn:first-child {
        margin-left: 0;
      }
    }
  }
}
.ant-tooltip.note-tooltip-common {
  .ant-tooltip-inner {
    position: relative;
    color: $body-color;
    background: #f3f0ff;
    border-radius: 8px;
    padding: 8px 8px 8px 32px;
  }
  .ic-question {
    position: absolute;
    top: 12px;
    left: 10px;
    color: #8270db;
  }
}
.ant-popover {
  .ant-popover-inner {
    border-radius: 10px;
  }
}
.popover-on-table-header {
  z-index: 1080 !important;
  .ant-popover-inner-content {
    padding: 0;
    > * {
      padding: 12px 16px;
    }
  }
}
