.ant-modal-confirm {
  .ant-modal-content {
    border-radius: 10px;
  }
  .custom-close-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .custom-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .ant-btn {
      min-width: 100px;
      margin: 3px 0 3px 8px;
    }
  }
}
.modal-content-overflow-hidden .ant-modal-content {
  overflow: hidden;
}
.modal-content-rounded-10 .ant-modal-content {
  border-radius: 10px;
}
.ant-modal {
  &.p-0-modal-body .ant-modal-body {
    padding: 0;
  }
  &.border-radius-8 {
    .ant-modal-content {
      border-radius: 8px;
    }
  }
  &.full-screen-modal {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    .ant-modal-content {
      height: 100% !important;
      border-radius: 0 !important;
    }
  }
}
.ant-modal-title {
  color: $primary;
}
.hide-modal-close .ant-modal-close {
  display: none;
}
.ant-modal-confirm {
  &.hide-modal-confirm-btns {
    .ant-modal-confirm-btns {
      display: none;
    }
  }
  &.show-close-button {
    .ant-modal-close {
      display: block;
    }
  }
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  animation-duration: 0.2s;
}
.hide-modal-header .ant-modal-header {
  display: none;
}
.modal-footer-common .ant-modal-footer,
.modal-footer-common.ant-modal-footer {
  text-align: center;
  .ant-btn {
    min-width: 100px;
    margin: 0 0.5rem;
  }
}
.modal-fixed-header {
  // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
  overflow: hidden;
  .ant-modal {
    padding-bottom: 0;
  }
  .modal-body-with-scroll {
    max-height: calc(100vh - 110px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.c-import-preview-modal {
  max-width: 100%;
  .modal-body-with-scroll .ant-table-content {
    overflow: auto !important;
    height: calc(100vh - 195px);
  }
  .main-table {
    .ant-table-row-expand-icon-cell {
      padding: 0 10px;
    }
  }
  .ant-result-title {
    font-size: 16px !important;
  }
}
