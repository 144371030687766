.title-lg-style {
  display: table;
  > * {
    display: table-cell !important;
  }
  .txt-id,
  .c-editable-input .txt-label,
  .c-editable-input .ant-input {
    font-weight: 500;
    font-size: 22px;
  }
  .txt-id {
    white-space: nowrap;
    padding-top: 1px;
  }
  .c-editable-input {
    &.is-editing {
      width: 100%;
      & + .box-right {
        display: none !important;
      }
    }
    .txt-label {
      padding-left: 5px;
    }
    .ant-input {
      padding: 0 11px;
    }
  }
}
