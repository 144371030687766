@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-upload-for-import-file {
  border-radius: 5px;
  .ant-upload {
    .ant-upload-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ant-upload.ant-upload-drag {
    border-radius: 5px;
  }
  .file-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    font-size: 0.9em;
    border: 1px solid #f0f0f0;
    padding: 5px;
    .file-name {
      flex: 0 0 calc(100% - 80px);
      text-align: left;
    }
    .file-size {
      opacity: 1;
      flex: 0 0 80px;
      text-align: right;
      transition: all 0.4s ease;
    }
    .btn-remove-file {
      opacity: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 2;
    }
  }
  .file-info {
    &:hover {
      .file-size {
        opacity: 0;
        visibility: hidden;
      }
      .btn-remove-file {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
