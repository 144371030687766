@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-bulk-change-tickets-dropdown {
  .ant-dropdown-menu,
  .ant-menu {
    width: 200px;
  }
  .ant-dropdown-menu-submenu-expand-icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .external-system-label {
    top: -3px;
    width: 36px;
    height: 16px;
    font-size: 10px;
    &:after {
      border-left-width: 4px;
      border-right-width: 2px;
      border-top-width: 4px;
    }
  }
}
.c-bulk-change-tickets-submenu {
  min-width: 230px;
}
