.c-create-defect-modal-1 {
  .form-wrapper {
    .create-defect-type {
      .ant-select-selector {
        border: none;
        box-shadow: none !important;
        padding-left: 0;
      }
      label {
        background: #f0f2f5;
        border-radius: 5px;
        padding-left: 8px;
        .ant-select-selector {
          background: #f0f2f5;
          border-radius: 5px;
        }
      }
    }
  }
}
