.c-out-of-free-transaction{
  .ant-modal-transaction-content{
    display: flex;
    .wrapper-left{
      width: 60%;
      padding-top: 20px;
    }
    .wrapper-right{
      width: 40%;
    }
  }
}