@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-error {
  text-align: center;
  .txt-title {
    display: table;
    margin: 0 auto 20px;
    .text {
      font-weight: 500;
      font-size: 18px;
      color: #333232;
      margin-bottom: 10px;
      @include mq(max_sm) {
        font-size: 16px;
      }
      span {
        display: block;
        font-size: 28px;
        @include mq(max_sm) {
          font-size: 24px;
        }
      }
    }
    .line {
      position: relative;
      overflow: hidden;
      .anticon {
        position: relative;
        font-size: 18px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          left: -10px;
          top: 50%;
          transform: translate(-100%, 0);
          width: 100vh;
          height: 1px;
          background: #333232;
        }
        &:after {
          display: block;
          content: '';
          position: absolute;
          right: -10px;
          top: 50%;
          transform: translate(100%, 0);
          width: 100vh;
          height: 1px;
          background: #333232;
        }
      }
    }
  }
  .txt-code {
    font-weight: 500;
    font-size: 80px;
    line-height: 1;
    color: transparent;
    background: $primary;
    background: -moz-linear-gradient(left, $primary 0%, #f57874 100%);
    background: -webkit-linear-gradient(left, $primary 0%, #f57874 100%);
    background: linear-gradient(to right, $primary 0%, #f57874 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b70f0a', endColorstr='#f57874',GradientType=1 );
    -webkit-background-clip: text;
    background-clip: text;
    margin-bottom: 40px;
    @include mq(max_sm) {
      font-size: 70px;
    }
    @include mq(max_xs) {
      font-size: 50px;
    }
  }
  .box-btn {
    .ant-btn {
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 10px;
      @include mq(max_sm) {
        margin: 0 5px;
      }
      @include mq(max_xs) {
        font-size: 14px;
      }
    }
  }
}
