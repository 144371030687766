html {
  --antd-wave-shadow-color: #951b16;
  --scroll-bar: 0;
}
body {
  font-size: 14px;
  color: $body-color;
}
a,
button {
  cursor: pointer;
  transition: all 0.4s ease;
}
a {
  color: $primary;
  &:hover {
    color: lighten($primary, 10%);
  }
  &:active {
    color: darken($primary, 10%);
  }
}
img {
  max-width: 100%;
}
hr {
  border: 0;
  border-bottom: 1px solid $border-color;
}
body > iframe {
  display: none;
}
.App {
  overflow-x: hidden;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @include mq(sm) {
    max-width: 540px;
  }
  @include mq(md) {
    max-width: 720px;
  }
  @include mq(lg) {
    max-width: 960px;
  }
  @include mq(xl) {
    max-width: 1140px;
  }
  @media only screen and (min-width: 1400px) {
    max-width: 1320px;
  }
  @include mq(xxl) {
    max-width: 1540px;
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #951b16;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #951b16;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #951b16;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-spin {
  color: $primary;
}
.ant-empty {
  width: 100%;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]):after {
  background-color: $primary;
}
body.full-screen-body {
  .c-main-header {
    display: none;
  }
}
