@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-manager-search {
  min-height: 46px;
  background: #f1f1f1;
  border-radius: 8px;
  .col-data-source,
  .col-fields {
    padding: 3px 10px;
  }
  .col-data-source {
    background: #f5e8e8;
    border-radius: 8px 0 0 8px;
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  .col-fields {
  }
  .btn-field {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: $body-color !important;
    background: transparent !important;
    border-color: transparent !important;
    border-radius: 8px;
    box-shadow: none;
    padding-left: 8px;
    padding-right: 8px;
    &.ant-dropdown-open,
    &:hover {
      background: darken(#f1f1f1, 5%) !important;
    }
    &[disabled] {
      color: #979797 !important;
      background: #e8e8e8 !important;
    }
    > span {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .txt-label {
      display: block;
      max-width: 230px;
    }
    .anticon {
      margin-left: 3px;
    }
    .ic-caret-down {
      font-size: 0.73em;
    }
    .ic-close {
      font-size: 1em;
      color: #999;
      transition: all 0.4s ease;
      &:hover {
        color: $danger;
      }
    }
  }
  .btn-more {
    &:after {
      display: none;
    }
  }
}

.c-field-user-dropdown {
  .title-label {
    font-weight: 500;
    padding: 5px 12px 8px 12px;
    user-select: none;
  }
}

.c-field-status-dropdown {
  .title-label {
    font-weight: 500;
    padding: 5px 12px 8px 12px;
    user-select: none;
  }
  .ant-tabs {
    .ant-tabs-nav {
      padding-left: 12px;
      margin-bottom: 0;
      .ant-tabs-tab {
        padding: 6px 0;
      }
    }
  }
}

.c-field-priority-dropdown {
  .title-label {
    font-weight: 500;
    padding: 5px 12px 8px 12px;
    user-select: none;
  }
  .ant-tabs {
    .ant-tabs-nav {
      padding-left: 12px;
      margin-bottom: 0;
      .ant-tabs-tab {
        padding: 6px 0;
      }
    }
  }
}

.ant-dropdown {
  .ant-dropdown-menu-item {
    .select-item {
      display: flex;
      align-items: center;
    }
  }
}

.c-field-string-number {
  .operation-field-string-number {
    display: flex;
    .ant-select {
      width: unset;
    }

    .c-editable-select-option {
      .ant-select-selector {
        min-width: 240px;
      }
    }
  }
}
