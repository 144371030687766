// -------------------- Variable -------------------- //

// ---------- Breakpoint => https://www.antdv.com/components/layout/#breakpoint-width
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

// ---------- Color
// $primary: #f57874;
$primary: #b70f0a;
$success: #28a745;
$warning: #faad14;
$danger: #ff4d4f;
$yellow-star: #f4c261;
$orange: #ff8c00;
$skyblue: #3d8af7;
$green: #629c44;
$focus-input: #919191;
$body-color: rgba(0, 0, 0, 0.85);
$body-dark-color: rgba(255, 255, 255, 0.85);
$border-color: #d9d9d9;
$border-dark-color: #303030;
$background-color: rgb(253, 244, 243);
$background-hover-color: rgba(176, 11, 4, 0.34);
$jira-color: #0c66e4;

// ----------  Size
$header-height: 60px;

// ---------- Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 2
    )
  ),
  $spacers
);
