@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-react-pan-zoom-image {
  position: relative;
  .pan-container img {
    max-width: 100%;
    max-height: 100%;
    &[src*="data:image/svg"] {
      width: 100%;
    }
  }
  .box-action {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0);
    border-radius: 50px;
    background: rgba(#000, 0.9);
    padding: 2px;
    z-index: 2;
    .ant-btn {
      color: #fff;
      border: 0;
      background: transparent;
      &:hover {
        background: #6d6d6d;
      }
    }
    .txt-zoom-percent {
      color: #fff;
    }
  }
  .input-zoom {
    width: auto;
    background: none;
    border: 0;
    outline: 0;
    box-shadow: none;
    padding: 0;
    .ant-input-number-handler-wrap {
      display: none;
    }
    input {
      width: auto;
      padding: 0;
    }
  }
}

.c-zoom-popover {
  .input-zoom {
    width: 120px;
    color: $body-color;
  }
}
