.ant-menu:not(.ant-menu-horizontal) {
  &.menu-style-2 {
    .ant-menu-item::after {
      left: 0;
      right: auto;
      border-right: 10px solid rgba(#fff, 0.98);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    .ant-menu-item {
      background: #fff;
      &.ant-menu-item-selected {
        background: $primary;
        .anticon,
        a {
          color: #fff;
        }
      }
    }
    .ant-menu-item-disabled,
    .ant-menu-submenu-disabled {
      background: #b48e8d !important;
      a,
      .ant-menu-item-icon {
        color: #d4d4d4 !important;
      }
    }
  }
  &.ant-menu-inline-collapsed.menu-style-2 {
    .ant-menu-submenu {
      background: #fff;
      &.ant-menu-submenu-selected {
        background: $primary;
        .anticon,
        a {
          color: #fff;
        }
      }
      .ant-menu-submenu-title {
        margin-bottom: 0;
      }
    }
  }
  .ant-menu-item {
    color: inherit;
    a:hover,
    .anticon:hover {
      color: $primary;
    }
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: initial;
      content: '';
    }
    &:after {
      border-right-color: $primary;
    }
    &.ant-menu-item-selected {
      background: transparent;
      &:before {
        background: rgba($primary, 0.05);
      }
      .anticon,
      a {
        color: $primary;
      }
    }
    .anticon {
      vertical-align: middle;
      svg {
        vertical-align: 0.5em;
      }
      img {
        max-width: 20px;
        max-height: 20px;
      }
    }
  }
  .ant-menu-submenu {
    &.ant-menu-submenu-selected {
      color: $primary;
    }
  }
  .ant-menu-sub {
    background: none;
  }
  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: $primary;
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent;
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
    color: $primary;
  }
  .ant-menu-item-group {
    &.no-group {
      .ant-menu-item-group-title {
        display: none;
      }
    }
    .ant-menu-item-group-title {
      padding-bottom: 0;
    }
  }
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from($primary), to($primary));
  background: linear-gradient(to right, $primary, $primary);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from($primary), to($primary));
  background: linear-gradient(to right, $primary, $primary);
}
.ant-menu-dark:not(.ant-menu-horizontal).ant-menu-submenu-popup .ant-menu-item-selected {
  background: transparent;
  .anticon,
  a {
    color: $primary;
  }
}
.ant-layout-sider {
  .ant-menu-submenu {
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: initial;
      content: '';
    }
  }
  &.ant-layout-sider-collapsed {
    .ant-menu-submenu {
      &.ant-menu-submenu-selected {
        &:before {
          background: rgba($primary, 0.05);
        }
      }
    }
  }
}
.ant-menu-submenu-popup {
  background: #fff;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  color: $primary;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: $primary;
}
.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item-selected a:hover {
  color: $primary;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after {
  border-bottom: 2px solid $primary;
}
.ant-menu.style-like-as-dropdown-menu {
  .ant-menu-item,
  .ant-menu-submenu .ant-menu-submenu-title {
    height: auto;
    line-height: normal;
    padding: 5px 12px;
    margin: 0;
    &:hover {
      background-color: #f5f5f5;
    }
    &:before {
      display: none;
    }
  }
  .ant-menu-item,
  .ant-menu-submenu {
    .ant-checkbox-wrapper {
      span {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
    .ant-checkbox {
      & + span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      line-height: calc(22 / 14);
      padding-right: 25px;
    }
  }
  .ant-menu {
    .ant-menu-title-content {
      line-height: calc(22 / 14);
    }
  }
}
