.c-progress-bar {
  width: 100%;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  .wrap-float-widget {
    display: inline;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
}
.progress-frame-widget {
  min-width: 300px;

  &.expanse {
    padding: 10px 15px 10px 15px;
  }

  &.collapse {
    border-top-left-radius: 15px;
    min-height: 30px;
  }
  position: relative;
  z-index: 1000;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  .wrap-progress {
    .header {
      display: flex;
      align-items: center;
      padding: 10px 15px 10px 15px;
      background-color: #f8fafd;
      justify-content: space-between;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
    }
  }
  .agent-list {
    .agent {
      display: flex;
      align-items: center;
      min-width: 100px;
      margin-left: 15px;
      .status-icon {
        width: 15px;
        height: 15px;
        background: #4caf50;
        border-radius: 50%;
      }
    }
    .ant-list-item {
      gap: 10px;
      display: flex;
      .ant-progress-line {
        width: 200px;
      }
    }
  }
}
.wrap-frame-collapse {
  .progress-frame-widget {
    height: 30px;
  }
}
.wrap-frame {
  position: relative;
}
