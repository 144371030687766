@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-progress-bar {
  position: relative;
  .progress-bar-popover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
.c-basic-progress-bar-wrapper {
  overflow: hidden;
  height: 5px;
  width: 100%;
  border-radius: 3px;
  background: #ddd;
}
.c-basic-progress-bar-popover {
  .ant-popover-inner {
    background: rgba(0, 0, 0, 0.95);
    border-radius: 10px;
  }
  .ant-popover-arrow {
    border-right-color: rgba(0, 0, 0, 0.95) !important;
    border-bottom-color: rgba(0, 0, 0, 0.95) !important;
  }
  .ant-popover-inner-content {
    color: #fff;
    padding: 0;
  }
  .box-content {
    width: 250px;
    padding: 12px 16px;
    .list {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .item {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 3px;
    }
  }
}
