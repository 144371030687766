@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-attachment-list {
  .ant-spin-container.ant-spin-blur {
    margin: 10px 0 20px;
  }
  .list {
    display: inline-flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0 -15px 0 0;
  }
  .item {
    width: 200px;
    margin-right: 15px;
    margin-bottom: 15px;
    .box-item {
      position: relative;
    }
    .btn-open-preview-file {
      display: block;
      background: none;
      outline: 0;
      border: 0;
      width: 100%;
      text-align: left;
      padding: 0;
      margin: 0;
    }
    .ic-other-file {
      width: 41%;
      height: 41%;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .box-file {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;
      border: 1px solid #f0f0f0;
      border-radius: 5px;
      padding: 5px;
      transition: all 0.4s ease;
      img {
        max-height: 100%;
      }
      .jira-icon {
        svg {
          width: 36px;
          height: 36px;
        }
      }
    }
    .box-name {
      display: block;
      text-align: left;
    }
    .box-meta {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      .txt-date {
        flex: 1 1 auto;
      }
    }
    .box-btns {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 10px;
      top: 118px;
      z-index: 2;
    }
  }
  .item:hover {
    .box-file {
      border-color: #d1d1d1;
      box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.09);
    }
    .box-btns {
      opacity: 1;
      visibility: visible;
    }
  }
  .item.file-not-found {
    .btn-open-preview-file {
      cursor: default;
    }
    .box-file {
      background: #f6f6f6;
      border-color: #f0f0f0 !important;
      box-shadow: none !important;
    }
  }
  .txt-no-attachment {
    text-align: center;
    padding-bottom: 10px;
  }
}
.c-basic-attachment-list.on-table {
  .list {
    margin-right: -8px;
  }
  .item {
    width: 85px;
    margin-right: 6px;
    margin-bottom: 10px;
    .box-file {
      height: 55px;
    }
    .box-name {
      font-size: 10px;
    }
    .box-meta {
      font-size: 8px;
      line-height: 1;
    }
    .box-btns {
      right: 3px;
      top: 30px;
      .ant-btn {
        width: 18px;
        svg {
          font-size: 12px;
        }
      }
    }
  }
}
