@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-status-label-for-workflow-dropdown {
  .ant-dropdown-menu {
    display: table;
    table-layout: fixed;
    width: auto;
    .ant-dropdown-menu-title-content {
      display: table-row;
      > * {
        display: table-cell;
        vertical-align: middle;
        padding: 5px 12px;
      }
      .c-status-label {
        width: 100%;
      }
    }
  }
}
