@import '../../assets/scss/_variables';
@import '../../assets/scss/mixins';

.c-steps-buttons-for-workflow {
  button {
    color: $primary;
    border-color: $primary;
    background: #fdf4f3;
    &:hover {
      background: darken(#fdf4f3, 8%);
    }
  }
}
