@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-import-progress {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 300px;
  overflow: hidden;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: all 0.4s ease;
  z-index: 990;
  .box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 45px;
    background: $primary;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    padding-left: 15px;
    .title-header {
      font-size: 16px;
      color: $primary;
      color: #fff;
    }
    .box-action {
      display: flex;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      .ant-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: 0;
        background: transparent;
        &:hover {
          background: rgba(255, 255, 255, 0.32);
        }
      }
    }
  }
  .box-body {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 250px;
    background: #fff;
    transition: all 0.4s ease;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        min-height: 50px;
        border-bottom: 1px solid #f5f5f5;
        padding: 5px 15px;
        &:last-child {
          border-bottom: 0;
        }
        .col-icon {
          flex: 0 0 45px;
          line-height: 1;
          .ant-spin-blur {
            opacity: 0.2;
          }
          .work-ticket-icon-wrapper {
            width: 38px;
            height: 38px;
          }
          .work-ticket-icon svg {
            width: 38px;
            height: 38px;
          }
        }
        .col-detail {
          flex: 1 1 calc(100% - 45px);
        }
        .txt-title {
          font-weight: 500;
          line-height: 1.4;
        }
        .txt-file-name {
          color: #929292;
          line-height: 1.4;
        }
        .box-progress {
          margin-top: -5px;
          margin-bottom: -2px;
          .ant-progress-line {
            line-height: 1;
          }
        }
      }
    }
  }
}
.c-import-progress {
  &.animated {
    .box-body {
      max-height: 0;
    }
  }
}
