@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-editable-input {
  display: flex;
  position: relative;
  width: auto;
  .ant-form-item {
    width: 100%;
    margin-bottom: 0;
  }
  .ant-form-item-explain {
    padding-right: 60px;
  }
  .ant-input,
  .ant-picker {
    width: 100%;
  }
  .box-txt-label {
    position: relative;
    overflow: hidden;
    min-width: 40px;
    border: 1px solid transparent;
    border-radius: 2px;
    padding-right: 29px;
    &:hover {
      border-color: $border-color;
      .btn-open-editable {
        opacity: 1;
      }
      .txt-label {
        background: #fff;
      }
    }
    .btn-open-editable {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      opacity: 0;
      border: 0;
      border-left: 1px solid $border-color;
      border-radius: 0 !important;
      background: #f0f0f0;
      transition: background 0.4s ease;
      &:hover {
        background: darken(#f0f0f0, 8%);
      }
      .anticon {
        font-size: 0.9em;
      }
    }
  }
}
.c-editable-input {
  &.is-editing {
    width: 100%;
    .basic-editor-wrapper {
      width: 100%;
    }
  }
  &.is-read-only {
    .box-txt-label:hover {
      border-color: transparent;
    }
  }
  &.show-editing-icon {
    .btn-open-editable {
      opacity: 1;
    }
  }
  &.hide-editing-icon {
    .btn-open-editable {
      display: none;
    }
  }
  &.is-show-editing-icon {
    .box-txt-label {
      &:hover {
        .btn-open-editable {
          border-left-color: $border-color;
          background: #f0f0f0;
        }
      }
      .btn-open-editable {
        opacity: 1;
        border-left-color: transparent;
        background: none;
      }
    }
  }
  &.editor-on-editable-table {
    min-width: 400px;
    .box-txt-label {
      width: 100%;
      height: 31.6px;
      border-color: $border-color;
      padding: 4px 11px;
      &:hover {
        border-color: $primary;
      }
    }
    .btn-open-editable {
      display: none;
    }
  }
}
