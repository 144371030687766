@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-maintenance-alert.ant-alert {
  border-radius: 5px;
  border: 1px solid #fbcccc;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  .ant-alert-description {
    color: #807A7A;
  }
}
