@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-attribute-list {
  .attribute-row-item {
    &:not(.first-row) {
      margin-top: 16px;
    }
  }
  .ant-form-item-label,
  .btn-assign-to-me {
    display: none;
  }
  .ant-select,
  .ant-input,
  .ant-picker,
  .ant-input-affix-wrapper {
    width: 100% !important;
  }
  .col-field-value {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
  .c-work-item-field.type-user {
    .ant-form-item-extra {
      min-height: 0;
    }
  }
}
