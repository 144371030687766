@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-quick-editing-drawer {
}

.c-quick-editing-drawer-modal {
  .ant-drawer-header {
    position: relative;
    padding: 7px 24px;
  }
  .ant-drawer-close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .ant-drawer-body {
    padding: 0;
    overflow-y: hidden;
  }
  .box-drawer-extra {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translate(0, -50%);
    .ant-btn {
      color: rgba(0, 0, 0, 0.45);
      &:hover {
        color: rgba(0, 0, 0, 0.75);
      }
    }
  }
  .modal-body-with-scroll {
    max-height: calc(100vh - 124px) !important;
  }
  .external-system-label {
    width: 32px;
    height: 15px;
    font-size: 10px;
    border-radius: 3px 3px 3px 0;
    &:after {
      border-left: 5px solid transparent;
      border-right: 2px solid transparent;
      border-top: 5px solid $jira-color;
    }
  }
}
