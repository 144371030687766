.c-basic-table {
  tr > td.ant-table-row-expand-icon-cell {
    text-align: left;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    .btn-toggle-expand {
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 10px);
        height: calc(100% + 10px);
      }
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .ant-table-thead {
    > tr {
      > th {
        background: #fff;
        white-space: nowrap;
        border-bottom-width: 2px;
      }
      > th {
        &.ant-table-column-has-sorters {
          transition: background-color 0.4s ease;
        }
      }
    }
  }
  tr {
    &.drop-over-downward > td {
      border-bottom: 2px dashed $primary;
    }
    &.drop-over-upward > td {
      border-top: 2px dashed $primary;
    }
    &.highlight-row {
      th,
      td {
        background: #fbf3f3 !important;
      }
    }
    &.ant-table-expanded-row {
      cursor: initial;
    }
    &.ant-table-expanded-row-level-1 .c-basic-table {
      padding: 10px 0 10px 150px;
      table {
        border-top: 1px solid #f0f0f0;
      }
      .ant-empty-normal {
        margin: 12px 0;
      }
    }
    &.ant-table-expanded-row-level-1 .c-basic-table {
      &.has-border-left-1 table {
        border-left: 1px solid #f0f0f0 !important;
      }
      &.has-border-right-1 table {
        border-right: 1px solid #f0f0f0 !important;
      }
      &.border-bottom-header-1 .ant-table-thead > tr > th {
        border-bottom-width: 1px;
      }
    }
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #fbf3f3;
  }
  .ant-table-content {
    display: block;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .ant-table-sticky-holder {
    z-index: 4;
    & + .ant-table-body {
      overflow: auto;
    }
  }
  .ant-table-selection-column {
    width: 50px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .ant-checkbox-wrapper {
      width: 100%;
    }
    .ant-checkbox {
      width: 100%;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .ant-checkbox-inner {
      margin: auto;
    }
    .ant-checkbox-checked::after {
      border: 0;
    }
  }
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    color: #afafaf;
  }
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    &.active {
      color: $primary;
    }
  }
  .ant-table-column-sorters {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-table-filter-trigger.active {
    color: $primary;
  }
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    color: $primary;
  }
}
.ant-table-content-overflow-initial .ant-table-content {
  overflow: initial !important;
}
.c-basic-table {
  &.hide-row-expand-icon {
    .ant-table-row-expand-icon {
      display: none;
    }
  }
  &.no-border {
    .ant-table-container,
    table,
    tr,
    tr th,
    tr td {
      border: 0 !important;
    }
  }
  &.has-border-bottom-table-content .ant-table-content {
    border-bottom: 1px solid #f0f0f0 !important;
  }
  &.no-border-whem-empty {
    .ant-table-content {
      overflow: initial !important;
    }
  }
  &.bg-header-table-gray {
    .ant-table-thead > tr > th {
      background: #f1f1f1;
    }
  }
  &.px-0-expanded-row .ant-table-expanded-row > .ant-table-cell {
    padding-top: 0;
    padding-bottom: 0;
  }
  &.ant-table-margin-0 {
    .ant-table {
      margin: 0 !important;
    }
  }
  &.no-overflow-x-scroll {
    .ant-table-content {
      overflow: initial !important;
    }
  }
}
.c-basic-table-pagination {
  .ant-pagination-options {
    @include mq(max_xs) {
      display: block;
    }
  }
}
.c-basic-table-pagination {
  &.ant-pagination {
    flex-wrap: wrap;
  }
}
.table-title-style {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ant-btn {
    width: auto;
    height: auto;
    line-height: 1;
    color: $body-color;
    border: 0;
    margin-left: 10px;
    .anticon {
      color: inherit;
    }
  }
  .ant-btn {
    &[disabled] {
      color: rgba(0, 0, 0, 0.35);
    }
  }
  .ant-btn:hover {
    color: $primary;
  }
  .btn-dots-option {
    margin-right: -5px;
  }
}
[class*='row-dragging'] {
  display: table-row !important;
  background: #fdf4f3 !important;
  border: 1px solid darken(#fdf4f3, 5%) !important;
  border-radius: 6px !important;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden !important;
  z-index: 9999 !important;
}
.ant-table-title {
  border: 1px solid $border-color;
  border-bottom: 0;
}
.ant-table-footer {
  border: 1px solid $border-color;
  border-top: 0;
}
.ant-table-cell-ellipsis .ant-table-column-sorters {
  max-width: 100%;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
}
.ant-table-cell .box-editing-field {
  overflow: hidden;
}
