@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-resize-panels {
  flex-wrap: nowrap;
  .resize-panel-right {
    z-index: 2;
  }
  .resize-panel-divider {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, 0);
    height: 100%;
    z-index: 3;
    .resize-control {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      width: 22px;
      height: 100%;
      cursor: ew-resize;
      .ic-drag {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        svg {
          width: 10px;
          height: 10px;
          color: darken($border-color, 20%);
          transition: all 0.4s ease;
        }
      }
    }
    .resize-control {
      &:active {
        width: 100px;
      }
      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        width: 1px;
        height: 100%;
        background: $border-color;
        transition: all 0.4s ease;
        z-index: 1;
      }
      &:hover {
        &:before {
          background: $primary;
        }
        .ic-drag svg {
          color: $primary;
        }
      }
    }
    .btn-collapse {
      opacity: 0;
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 10px;
      transform: translate(-50%, 0);
      width: 22px;
      height: 22px;
      cursor: pointer;
      font-size: 8px;
      background: #fff;
      border: 1px solid $border-color;
      border-radius: 50%;
      box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
      transition: all 0.4s ease;
      &:hover {
        color: $primary;
      }
    }
    .btn-expand {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      text-align: center;
      .anticon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translate(-50%, 0);
        transition: all 0.4s ease;
        &.ic-tree-folder {
          opacity: 1;
          font-size: 30px;
        }
        &.ic-right-outlined {
          opacity: 0;
          width: 22px;
          height: 22px;
          font-size: 8px;
          background: #fff;
          border: 1px solid $border-color;
          border-radius: 50%;
          &:hover {
            color: $primary;
          }
        }
      }
    }
    .btn-expand {
      &:hover {
        .anticon.ic-tree-folder {
          opacity: 0;
        }
        .anticon.ic-right-outlined {
          opacity: 1;
        }
      }
    }
  }
  .resize-panel-divider:hover .btn-collapse {
    opacity: 1;
    visibility: visible;
  }
}
.c-resize-panels {
  &.is-collapsed {
    .resize-panel-right {
      padding-left: 10px !important;
    }
    .resize-panel-content {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      overflow: hidden;
    }
    .resize-panel-divider {
      width: 100%;
      transform: none;
      background: #f6f6f6;
      border-radius: 8px;
      .btn-collapse {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
