@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-test-step-draggable-row-table {
  .c-editable-cell .cell-text.is-editable-field {
    &:hover {
      background: rgb(219 219 219 / 15%) !important;
    }
  }
}
