@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-search {
  min-height: 46px;
  background: #f1f1f1;
  border-radius: 8px;
  .col-data-source,
  .col-fields {
    padding: 3px 10px;
  }
  .col-data-source {
    background: #f5e8e8;
    border-radius: 8px 0 0 8px;
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  .col-fields {
  }
  .btn-field {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 230px;
    color: $body-color !important;
    background: transparent !important;
    border-color: transparent !important;
    border-radius: 8px;
    box-shadow: none;
    padding-left: 8px;
    padding-right: 8px;
    &.ant-dropdown-open,
    &:hover {
      background: darken(#f1f1f1, 5%) !important;
    }
    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .anticon {
      margin-left: 3px;
    }
    .ic-caret-down {
      font-size: 0.73em;
    }
    .ic-close {
      font-size: 1em;
      color: #999;
      transition: all 0.4s ease;
      &:hover {
        color: $danger;
      }
    }
  }
  .btn-more {
    &:after {
      display: none;
    }
  }
  .c-data-source-selection {
    .ant-select-selector {
      height: auto;
      border: 0;
      background: none;
      box-shadow: none !important;
      padding: 0;
    }
    .ant-select-selection-item {
      font-weight: 500;
      color: $primary;
    }
    .ant-select-arrow {
      right: 0;
      font-size: 9px;
    }
  }
}
