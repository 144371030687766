@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-breadcrumb {
  margin-bottom: 12px;
  a {
    &:hover {
      color: $primary;
    }
  }
  .ant-breadcrumb-link {
    color: rgba(0, 0, 0, 0.85);
  }
  .keep-link {
    color: rgba(0, 0, 0, 0.45) !important;
    &:hover {
      color: $primary !important;
    }
  }
}
