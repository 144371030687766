@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-column-chooser-2 {
  .btn-column-chooser {
    line-height: 1;
    color: $body-color;
  }
}

@keyframes cssAnimation {
  0% {
    background-color: #e1e1e1;
  }
  70% {
    background-color: #f6f6f6;
  }
  100% {
    background-color: none;
  }
}
.c-column-chooser-dropdown-2 {
  width: 368px !important;
  .ant-dropdown-menu-item {
    min-width: 180px;
    &:not(.has-padding) {
      padding: 0;
    }
    .ant-checkbox-wrapper {
      padding: 5px 12px;
      span {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }
  .wrapper-active-column {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    .ant-empty-normal {
      margin: unset;
      padding: 32px 0px;
    }
    .ant-dropdown-menu {
      box-shadow: none !important;
      border-radius: 0 !important;
    }
    .latest-element {
      animation: cssAnimation 1.5s forwards;
    }
    .add-new-column {
      text-align: center;
      padding: 0 15px;
      span {
        font-size: 13px;
        cursor: pointer;
        font-weight: 400;
      }
      .ant-select {
        cursor: pointer;
        width: 100%;
        .ant-select-selector {
          box-shadow: none !important;
          border: none;
          background: #f3f4f9;
        }
      }
    }
    .ant-divider-horizontal {
      width: 85%;
      min-width: 85%;
      margin: 12px auto;
    }
    .button-action {
      padding: 15px 15px 10px 15px;
      justify-content: flex-end;
      display: flex;
      gap: 10px;
    }
  }
}
.c-column-chooser-dropdown-2 .wrapper-active-column .field-btn,
.field-btn.row-dragging-field-2 {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px !important;
  color: #6c6b6b;
  &:hover {
    .txt-title {
      color: $primary;
    }
  }
  .btn-drag {
    color: #ccc;
    transition: all 0.4s ease;
    &:hover {
      color: $primary;
    }
  }
  .btn-delete {
    color: #ccc;
    transition: all 0.4s ease;
    &:hover {
      color: $danger;
    }
  }
}
.field-btn.row-dragging-field-2 {
  background: #fdf4f3 !important;
  border: 1px solid darken(#fdf4f3, 5%) !important;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1) !important;
  transition: none !important;
  z-index: 9999 !important;
}
