@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-basic-comment {
  .btn-reload-on-detail-page {
    position: absolute;
    right: 0;
    top: -46px;
    z-index: 2;
  }
  .comment-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .ant-collapse {
    background: none;
    border-top: 1px solid $border-color;
  }
  .ant-collapse-item {
    padding: 10px;
    .ant-collapse-header {
      display: flex;
      padding: 0;
      .ant-collapse-arrow {
        top: 7px;
        left: 0;
        vertical-align: 0;
        font-size: 0.7em;
        margin-right: 6px;
      }
      .txt-name {
        display: inline-flex;
        align-items: center;
      }
    }
    .ant-collapse-extra {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;
    }
    .ant-collapse-content {
      padding: 0;
    }
    .ant-collapse-content-box {
      padding-bottom: 0;
      padding-right: 0;
    }
  }
  .ant-collapse-item:hover {
    background: #f3f3f3;
    .ant-collapse-extra {
      opacity: 1;
      visibility: visible;
    }
  }
}
