@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.c-error-404 {
  max-width: 897px;
  padding: 0px 16px;
  margin: auto;
  position: relative;
  text-align: center;
  .not-found-description {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 10%;
    padding: 0px 16px;
    & > h1 {
      font-size: 42px;
      font-weight: 700;
    }
    & > p {
      font-size: 16px;
    }
  }
  .box-btn {
    .ant-btn {
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 10px;
      @include mq(max_sm) {
        margin: 0 5px;
      }
      @include mq(max_xs) {
        font-size: 14px;
      }
      &:hover {
        border: 1px solid #ea2127 !important;
        color: #ea2127;
      }
    }
    & > a {
      background-color: #ea2127;
      color: #ffffff;
      &:hover {
        background-color: #ffffff;
        color: #ea2127;
      }
    }
  }
}
